import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import { Link } from "react-router-dom";
import { capitalizeEachWord } from "../../utils/utils";
import { useSortableData } from "../../utils/sort";
import { Table, Button } from "react-bootstrap";
import DeleteButton from "../../components/DeleteHandler";
import { deleteOutlet, updateOutlet } from "../../actions/outletActions";
import Popup from "../../components/Popup";
import OutletForm from "./OutletPopupBody";
import Loader from "../../components/Loader";

const OutletTableView = ({ keyword, outlets, loading, error, refetch }) => {
 const [showPopup, setShowPopup] = useState(false);
 const [editOutletData, setEditOutletData] = useState({
  outletID: 0,
  name: "",
  type: "",
  address: "",
 });

 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo } = userLogin;

 const dispatch = useDispatch();

 const submitHandler = async (event) => {
  if (event) {
   event.preventDefault(); // Mencegah halaman refresh
  }
  if (!editOutletData) return;

  await dispatch(updateOutlet(editOutletData));
  //refetch();
  setShowPopup(false);
  setEditOutletData(null);
 };

 const deleteHandler = async () => {
  await dispatch(deleteOutlet(editOutletData));
  //refetch();
  setEditOutletData(null);
 };

 const ProductTable = ({ products }) => {
  const { items, requestSort, getClassNamesFor } = useSortableData(products);

  return (
   <>
    <Table striped bordered hover size="sm" className="custom-table">
     <thead className="sticky-header">
      <tr>
       <th style={{ textAlign: "center" }}>
        <button
         type="button"
         onClick={() => requestSort("outletID")}
         className={`${getClassNamesFor("outletID")} sort-btn`}
        >
         Outlet ID
        </button>
       </th>
       <th style={{ textAlign: "left" }}>
        <button
         type="button"
         onClick={() => requestSort("name")}
         className={`${getClassNamesFor("name")} sort-btn`}
        >
         Name
        </button>
       </th>
       <th style={{ textAlign: "left" }}>
        <button
         type="button"
         onClick={() => requestSort("type")}
         className={`${getClassNamesFor("type")} sort-btn`}
        >
         Type
        </button>
       </th>
       <th style={{ textAlign: "left" }}>Action</th>
      </tr>
     </thead>
     <tbody style={{ color: "#000000" }}>
      {userInfo.isAdmin &&
       Array.isArray(items) &&
       items.map(
        (item, index) =>
         item && (
          <tr key={item._id}>
           <td>{item.outletID}</td>
           <td>
            <Link to={`/outlet/${item._id}`} className="link-black">
             {capitalizeEachWord(item.name)}
            </Link>
           </td>
           <td>{item.type ? capitalizeEachWord(item.type) : ""}</td>
           <td>
            <Button
             variant="light"
             className="btn-sm"
             onClick={() => {
              setShowPopup(true);
              setEditOutletData(item);
             }}
            >
             <i className="fas fa-edit"></i>
            </Button>
            <DeleteButton
             type="Outlet"
             item={item}
             deleteAction={deleteHandler}
            />
           </td>
          </tr>
         )
       )}
     </tbody>
    </Table>
   </>
  );
 };

 return (
  <>
   {loading ? (
    <Loader />
   ) : error ? (
    <Message variant="danger">{error}</Message>
   ) : (
    <>
     <ProductTable products={outlets} />
     <Popup
      show={showPopup}
      onHide={() => setShowPopup(false)}
      title="Edit Outlet"
      onApply={submitHandler}
     >
      <OutletForm
       formData={editOutletData}
       onChange={(updatedData) => {
        setEditOutletData(updatedData);
       }}
       isEditMode
       onSubmit={submitHandler}
      />
     </Popup>
    </>
   )}
  </>
 );
};

export default OutletTableView;
