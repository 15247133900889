import {
 ATTENDANCE_DATA_ENTER_FAIL,
 ATTENDANCE_DATA_ENTER_REQUEST,
 ATTENDANCE_DATA_ENTER_PROGRESS,
 ATTENDANCE_DATA_ENTER_SUCCESS,
 ATTENDANCE_DATA_ENTER_COMPLETE,
 ATTENDANCE_ANALYSIS_FAIL,
 ATTENDANCE_ANALYSIS_REQUEST,
 ATTENDANCE_ANALYSIS_SUCCESS,
 ATTENDANCE_DELETE_REQUEST,
 ATTENDANCE_DELETE_SUCCESS,
 ATTENDANCE_DELETE_FAIL,
 ATTENDANCE_DETAILS_REQUEST,
 ATTENDANCE_DETAILS_SUCCESS,
 ATTENDANCE_DETAILS_ERROR,
} from "../constants/attendanceConstant";
import axios from "axios";
import store from "../store";
import { getAxiosTargetPath } from "../utils/utils";

// postAttendance untuk import data attendance dari excel ke Attendance server
export const postAttendance =
 (attendance, fileType = "excel") =>
 async (dispatch, getState) => {
  try {
   dispatch({ type: ATTENDANCE_DATA_ENTER_REQUEST });

   const {
    userLogin: { userInfo },
   } = getState();

   const config = {
    headers: {
     Authorization: `Bearer ${userInfo?.token}`,
    },
   };

   const MAX_ARRAY_SIZE = 200; // Example chunk size
   const attendanceChunks = [];

   // Split attendance data into chunks
   for (let i = 0; i < attendance?.length; i += MAX_ARRAY_SIZE) {
    attendanceChunks?.push(attendance?.slice(i, i + MAX_ARRAY_SIZE));
   }

   // Total number of chunks
   const totalChunks = attendanceChunks?.length;

   for (let i = 0; i < totalChunks; i++) {
    const chunk = attendanceChunks[i];
    const { data } = await axios.post(
     `/attendance/postAttendance/${fileType}`,
     chunk,
     config
    );

    // Dispatch success action
    dispatch({
     type: ATTENDANCE_DATA_ENTER_SUCCESS,
     payload: data,
    });

    // Dispatch progress update
    const progress = Math.round(((i + 1) / totalChunks) * 100);
    dispatch({
     type: ATTENDANCE_DATA_ENTER_PROGRESS,
     payload: progress,
    });

    if (i === totalChunks - 1) {
     // Dispatch final success action
     dispatch({
      type: ATTENDANCE_DATA_ENTER_COMPLETE,
      payload: data,
     });
    }
   }
  } catch (error) {
   dispatch({
    type: ATTENDANCE_DATA_ENTER_FAIL,
    payload:
     error.response && error.response.data.message
      ? error.response.data.message
      : error.message,
   });
  }
 };

//addAttendance untuk add data attendance secara manual
export const addAttendance = (attendance) => async (dispatch, getState) => {
 try {
  dispatch({ type: ATTENDANCE_DATA_ENTER_REQUEST });

  const {
   userLogin: { userInfo },
  } = getState();

  const config = {
   headers: {
    Authorization: `Bearer ${userInfo?.token}`,
   },
  };

  const { data } = await axios.post(`/attendance/add`, attendance, config);

  // Dispatch success action
  dispatch({
   type: ATTENDANCE_DATA_ENTER_SUCCESS,
   payload: data,
  });

  // Dispatch progress update
  const progress = 100;
  dispatch({
   type: ATTENDANCE_DATA_ENTER_PROGRESS,
   payload: progress,
  });

  // Dispatch final success action
  dispatch({
   type: ATTENDANCE_DATA_ENTER_COMPLETE,
  });
 } catch (error) {
  dispatch({
   type: ATTENDANCE_DATA_ENTER_FAIL,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const deleteAttendance = (id) => async (dispatch, getState) => {
 try {
  dispatch({ type: ATTENDANCE_DELETE_REQUEST });

  const {
   userLogin: { userInfo },
  } = getState();

  const config = {
   headers: {
    Authorization: `Bearer ${userInfo?.token}`,
   },
  };

  const { data } = await axios.delete(`/attendance/delete/${id}`, config);

  // Dispatch success action
  dispatch({
   type: ATTENDANCE_DELETE_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: ATTENDANCE_DELETE_FAIL,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const getAnalysisByDate = (date) => async (dispatch, getState) => {
 try {
  dispatch({ type: ATTENDANCE_ANALYSIS_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo?.token}`,
   },
  };
  const { data } = await axios.post(
   `/attendance/getAnalysis`,
   { date: date },
   config
  );

  dispatch({
   type: ATTENDANCE_ANALYSIS_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: ATTENDANCE_ANALYSIS_FAIL,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const deleteAttendanceByDate = (days) => async (dispatch, getState) => {
 try {
  dispatch({ type: ATTENDANCE_DELETE_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo?.token}`,
   },
  };
  const { data } = await axios.delete(`/attendance/${days}`, config);

  dispatch({
   type: ATTENDANCE_DELETE_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: ATTENDANCE_DELETE_FAIL,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const getAttendanceDetails = (id) => async (dispatch, getState) => {
 try {
  dispatch({ type: ATTENDANCE_DETAILS_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo?.token}`,
   },
  };

  const { data } = await axios.get(`/attendance/${id}`, config);

  dispatch({
   type: ATTENDANCE_DETAILS_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: ATTENDANCE_DETAILS_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const getLatestAttendance = async () => {
 const {
  userLogin: { userInfo },
 } = store.getState();

 const config = {
  headers: {
   Authorization: `Bearer ${userInfo?.token}`,
  },
 };
 const axiosPath = getAxiosTargetPath(`/attendance/latest`);
 const { data } = await axios.get(axiosPath, config);
 return data;
};
