import React from "react";
import { Col } from "react-bootstrap";
import { capitalizeEachWord } from "../../utils/utils.jsx";
import dayjs from "dayjs";

const LatestEmployeeUpdated = ({
 label = "Latest employee updated at: ",
 sheet,
 showEmployeeID = true,
 showOutlet = true,
 showPosition = true,
}) => {
 if (!sheet?.employeeLastUpdatedName) return "";

 return (
  <Col>
   <>
    {label + " "}
    {sheet?.employeeLastUpdatedAt
     ? `${dayjs(sheet?.employeeLastUpdatedAt).format(
        "YYYY-MM-DD HH:mm:ss wib"
       )} (`
     : "-"}
   </>
   {showEmployeeID && <>{sheet?.employeeID + " - "} </>}
   <>{capitalizeEachWord(sheet?.employeeLastUpdatedName) + " - "}</>
   {showOutlet && <>{capitalizeEachWord(sheet?.outlet) + " - "}</>}
   {showPosition && <>{capitalizeEachWord(sheet?.position) + ")"}</>}
  </Col>
 );
};

export default LatestEmployeeUpdated;
