import axios from "axios";
import {
 USER_DETAILS_FAIL,
 USER_DETAILS_REQUEST,
 USER_DETAILS_SUCCESS,
 USER_LOGIN_FAIL,
 USER_LOGIN_REQUEST,
 USER_LOGIN_SUCCESS,
 USER_LOGOUT,
 USER_REGISTER_FAIL,
 USER_REGISTER_REQUEST,
 USER_REGISTER_SUCCESS,
 USER_UPDATE_PROFILE_FAIL,
 USER_UPDATE_PROFILE_REQUEST,
 USER_UPDATE_PROFILE_SUCCESS,
 USER_DETAILS_RESET,
 USER_LIST_FAIL,
 USER_LIST_SUCCESS,
 USER_LIST_REQUEST,
 USER_LIST_RESET,
 USER_DELETE_REQUEST,
 USER_DELETE_SUCCESS,
 USER_DELETE_FAIL,
 USER_UPDATE_FAIL,
 USER_UPDATE_SUCCESS,
 USER_UPDATE_REQUEST,
} from "../constants/userConstants";
import { getAxiosTargetPath } from "../utils/utils";
import store from "../store";

export const login = (email, password) => async (dispatch) => {
 try {
  dispatch({
   type: USER_LOGIN_REQUEST,
  });

  const config = {
   headers: {
    "Content-Type": "application/json",
   },
  };

  const axiosPath = getAxiosTargetPath("/users/login");
  const { data } = await axios.post(axiosPath, { email, password }, config);

  dispatch({
   type: USER_LOGIN_SUCCESS,
   payload: data,
  });

  localStorage.setItem("userInfo", JSON.stringify(data));
 } catch (error) {
  dispatch({
   type: USER_LOGIN_FAIL,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const logout = () => (dispatch) => {
 localStorage.removeItem("userInfo");
 dispatch({ type: USER_LOGOUT });
 dispatch({ type: USER_DETAILS_RESET });
 dispatch({ type: USER_LIST_RESET });
 document.location.href = "/login";
};

export const register =
 (employeeID, name, email, password) => async (dispatch) => {
  try {
   dispatch({
    type: USER_REGISTER_REQUEST,
   });

   const config = {
    headers: {
     "Content-Type": "application/json",
    },
   };

   const axiosPath = getAxiosTargetPath("/users");
   const { data } = await axios.post(
    axiosPath,
    { employeeID, name, email, password },
    config
   );

   dispatch({
    type: USER_REGISTER_SUCCESS,
    payload: data,
   });

   dispatch({
    type: USER_LOGIN_SUCCESS,
    payload: data,
   });

   localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
   dispatch({
    type: USER_REGISTER_FAIL,
    payload:
     error.response && error.response.data.message
      ? error.response.data.message
      : error.message,
   });
  }
 };

export const getUserDetails = (id) => async (dispatch, getState) => {
 try {
  dispatch({
   type: USER_DETAILS_REQUEST,
  });

  const {
   userLogin: { userInfo },
  } = getState();

  const config = {
   headers: {
    Authorization: `Bearer ${userInfo?.token}`,
   },
  };

  const axiosPath = getAxiosTargetPath(`/users/${id}`);
  const { data } = await axios.get(axiosPath, config);

  dispatch({
   type: USER_DETAILS_SUCCESS,
   payload: data,
  });
 } catch (error) {
  const message =
   error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
  if (message === "Not authorized, token failed") {
   dispatch(logout());
  }
  dispatch({
   type: USER_DETAILS_FAIL,
   payload: message,
  });
 }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
 try {
  dispatch({
   type: USER_UPDATE_PROFILE_REQUEST,
  });

  const {
   userLogin: { userInfo },
  } = getState();

  const config = {
   headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userInfo?.token}`,
   },
  };

  const axiosPath = getAxiosTargetPath(`/users/profile`);
  const { data } = await axios.put(axiosPath, user, config);

  dispatch({
   type: USER_UPDATE_PROFILE_SUCCESS,
   payload: data,
  });
  dispatch({
   type: USER_LOGIN_SUCCESS,
   payload: data,
  });
  localStorage.setItem("userInfo", JSON.stringify(data));
 } catch (error) {
  const message =
   error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
  if (message === "Not authorized, token failed") {
   dispatch(logout());
  }
  dispatch({
   type: USER_UPDATE_PROFILE_FAIL,
   payload: message,
  });
 }
};

export const deleteUser = (id) => async (dispatch, getState) => {
 try {
  dispatch({
   type: USER_DELETE_REQUEST,
  });

  const {
   userLogin: { userInfo },
  } = getState();

  const config = {
   headers: {
    Authorization: `Bearer ${userInfo?.token}`,
   },
  };

  const axiosPath = getAxiosTargetPath(`/users/${id}`);
  await axios.delete(axiosPath, config);

  dispatch({ type: USER_DELETE_SUCCESS });
 } catch (error) {
  const message =
   error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
  if (message === "Not authorized, token failed") {
   dispatch(logout());
  }
  dispatch({
   type: USER_DELETE_FAIL,
   payload: message,
  });
 }
};

export const updateUser = (user) => async (dispatch, getState) => {
 try {
  dispatch({
   type: USER_UPDATE_REQUEST,
  });

  const {
   userLogin: { userInfo },
  } = getState();

  const config = {
   headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userInfo?.token}`,
   },
  };

  const axiosPath = getAxiosTargetPath(`/users/${user._id}`);
  const { data } = await axios.put(axiosPath, user, config);

  dispatch({ type: USER_UPDATE_SUCCESS });

  dispatch({ type: USER_DETAILS_SUCCESS, payload: data });

  dispatch({ type: USER_DETAILS_RESET });
 } catch (error) {
  const message =
   error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
  if (message === "Not authorized, token failed") {
   dispatch(logout());
  }
  dispatch({
   type: USER_UPDATE_FAIL,
   payload: message,
  });
 }
};

export const leaveLists = () => async (dispatch, getState) => {
 try {
  dispatch({
   type: USER_LIST_REQUEST,
  });

  const {
   userLogin: { userInfo },
  } = getState();

  const config = {
   headers: {
    Authorization: `Bearer ${userInfo?.token}`,
   },
  };

  const axiosPath = getAxiosTargetPath(`/leaveList`);
  const { data } = await axios.get(axiosPath, config);

  dispatch({
   type: USER_LIST_SUCCESS,
   payload: data,
  });
 } catch (error) {
  const message =
   error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
  if (message === "Not authorized, token failed") {
   dispatch(logout());
  }
  dispatch({
   type: USER_LIST_FAIL,
   payload: message,
  });
 }
};

export const forgotPassword = (email) => async (dispatch) => {
 try {
  dispatch({
   type: USER_REGISTER_REQUEST,
  });

  const config = {
   headers: {
    "Content-Type": "application/json",
   },
  };

  const axiosPath = getAxiosTargetPath("/users/forgotPassword");
  const { data } = await axios.post(axiosPath, { email }, config);

  dispatch({
   type: USER_REGISTER_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: USER_REGISTER_FAIL,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const resetPassword = (token, password) => async (dispatch) => {
 try {
  dispatch({
   type: USER_REGISTER_REQUEST,
  });

  const config = {
   headers: {
    "Content-Type": "application/json",
   },
  };

  const axiosPath = getAxiosTargetPath(`/users/resetPassword/${token}`);
  const { data } = await axios.post(axiosPath, { password }, config);

  dispatch({
   type: USER_REGISTER_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: USER_REGISTER_FAIL,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const updateUserLastSeen = async () => {
 const {
  userLogin: { userInfo },
 } = store.getState();

 const config = {
  headers: {
   Authorization: `Bearer ${userInfo?.token}`,
  },
 };
 const axiosPath = getAxiosTargetPath(`/users/lastSeen/${userInfo?._id}`);
 const { data } = await axios.get(axiosPath, config);
 return data;
};

export const getUsers = async () => {
 const {
  userLogin: { userInfo },
 } = store.getState();

 const config = {
  headers: {
   Authorization: `Bearer ${userInfo?.token}`,
  },
 };
 const axiosPath = getAxiosTargetPath(`/users`);
 const { data } = await axios.get(axiosPath, config);
 return data;
};
export const listUsers = () => async (dispatch, getState) => {
 try {
  dispatch({
   type: USER_LIST_REQUEST,
  });

  const {
   userLogin: { userInfo },
  } = getState();

  const config = {
   headers: {
    Authorization: `Bearer ${userInfo?.token}`,
   },
  };

  const axiosPath = getAxiosTargetPath(`/users`);
  const { data } = await axios.get(axiosPath, config);

  dispatch({
   type: USER_LIST_SUCCESS,
   payload: data,
  });
 } catch (error) {
  const message =
   error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
  if (message === "Not authorized, token failed") {
   dispatch(logout());
  }
  dispatch({
   type: USER_LIST_FAIL,
   payload: message,
  });
 }
};
