import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import IconStarRating from "./IconStarRating";
import Outlet from "./employee/Outlet";
import Position from "./employee/Position";
import Name from "./employee/Name";
import WorkDuration from "./employee/WorkDuration";
import Age from "./employee/Age";
import DateIn from "./employee/DateIn";
import Contact from "./employee/Contact";
import PhotoProfile from "./employee/PhotoProfile";
import CardFooterLastUpdate from "./employee/CardFooterLastUpdate";

const Employee = ({
 employee,
 outlet,
 detailsClassName = "justify-content-md-left",
}) => {
 if (!employee) return "";

 // Determine the className based on the outlet
 const cardClassName = `my-3 p-1 rounded ${
  (employee.outlet === "SETIABUDI" && "employee-card-setiabudi") ||
  (employee.outlet === "SUDIRMAN" && "employee-card-sudirman") ||
  (employee.outlet === "SRIWIJAYA" && "employee-card-sriwijaya") ||
  (employee.outlet === "MANAGEMENT" && "employee-card-sriwijaya") ||
  (employee.outlet === "KHALID MEDAN" && "employee-card-sriwijaya") ||
  (employee.outlet === "KHALID JAKARTA" && "employee-card-mahakam") ||
  (employee.outlet === "MAHAKAM" && "employee-card-mahakam") ||
  (employee.outlet !== "SETIABUDI" &&
   employee.outlet !== "SUDIRMAN" &&
   employee.outlet !== "SRIWIJAYA" &&
   employee.outlet !== "MANAGEMENT" &&
   employee.outlet !== "KHALID MEDAN" &&
   employee.outlet !== "KHALID JAKARTA" &&
   employee.outlet !== "MAHAKAM" &&
   "employee-card-mahakam")
 }`;

 return (
  <Card className={cardClassName} style={{ textAlign: "center" }}>
   <Card.Body>
    <Link to={`/employee/${employee._id}`} style={{ textDecoration: "none" }}>
     <Card.Title as="div">
      <Row>
       <Col>
        <PhotoProfile profilePicturePath={employee.profilePicture} />
       </Col>
      </Row>
     </Card.Title>
     <Name name={employee.name} status={employee.status} color="white" />
    </Link>
    <Contact
     phone={employee.phone}
     email={employee.email}
     ukuranBaju={employee.ukuranBaju}
    />
    <Row className="justify-content-md-center">
     <Col md="auto">
      <IconStarRating employee={employee} />
     </Col>
    </Row>
    <Link
     to={`/employee/${employee._id}`}
     style={{ textDecoration: "none" }}
     className="link-black"
    >
     <br />
     {!employee.phone && !employee.email && (
      <Row>
       <Col>
        <br />
        <br />
       </Col>
      </Row>
     )}
     <Outlet outlet={employee.outlet} className={detailsClassName} />
     <Position position={employee.position} className={detailsClassName} />
     <Row className={detailsClassName}>
      {
       <Col md="auto">
        <i
         className={`${
          employee.sex === "Men"
           ? "fas fa-mars male-icon"
           : "fas fa-venus female-icon"
         }`}
         style={{
          color: `${employee.sex === "Men" ? "green" : "pink"}`,
          fontSize: "1.25em",
         }}
        />
       </Col>
      }
      <Col md="auto">{employee.employeeID} </Col>
      <Col md="auto">{employee.education && employee.education} </Col>
     </Row>
     <DateIn
      dateIn={employee.dateIn}
      dateResign={employee.dateResign}
      className={detailsClassName}
     />
     <Age dateOfBirth={employee.dateOfBirth} className={detailsClassName} />
     <WorkDuration
      dateIn={employee.dateIn}
      dateResign={employee.dateResign}
      className={detailsClassName}
     />
    </Link>
   </Card.Body>
   <CardFooterLastUpdate employee={employee} />
  </Card>
 );
};

export default Employee;
