import { useMemo } from "react";
import dayjs from "dayjs";
import { getWorkDuration } from "../utils/utils"; // Assuming you have this utility function

const useFilteredEmployees = (employees, isActiveEmployee) => {
 return useMemo(() => {
  if (!Array.isArray(employees)) return [employees];

  // Filter employees based on isActiveEmployee status
  const filteredEmployees = employees.filter((item) =>
   isActiveEmployee === false ? item.dateResign : !item.dateResign
  );

  // Map through filtered employees to format dates and add work duration
  return filteredEmployees.map((item) => ({
   ...item,
   dateIn: item.dateIn ? dayjs(item.dateIn).format("DD-MMM-YYYY") : null,
   dateResign: item.dateResign
    ? dayjs(item.dateResign).format("DD-MMM-YYYY")
    : null,
   workingDuration: getWorkDuration(item.dateIn, item.dateResign),
  }));
 }, [employees, isActiveEmployee]);
};

export default useFilteredEmployees;
