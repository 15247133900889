import React from "react";
import PropTypes from "prop-types";
import { Form, Row, Col } from "react-bootstrap";
import { defaultOptions } from "../../data/etc";
const defaultSelect = defaultOptions;

const OutletForm = ({
 formData = {}, // Default value
 onChange = () => {},
 onSubmit = () => {},
 isEditMode = false,
 defaultOptions = defaultSelect,
}) => {
 if (!formData) return null;

 const { outletID = 0, name = "", type = "" } = formData;

 // Fungsi untuk menangani perubahan input
 const handleChange = (field, value) => {
  if (formData[field] !== value) {
   onChange({ ...formData, [field]: value });
  }
 };

 return (
  <Form onSubmit={onSubmit}>
   <Row>
    <Col md="3">
     <Form.Group controlId="outletID">
      <Form.Label>Outlet ID</Form.Label>
      <Form.Control
       type="number"
       placeholder="Outlet ID"
       value={outletID}
       onChange={(e) => handleChange("outletID", e.target.value)}
       onWheel={(e) => e.target.blur()}
       disabled={isEditMode} // Disable jika edit mode
       required
      />
     </Form.Group>
    </Col>
    <Col md="9">
     <Form.Group controlId="name">
      <Form.Label>Name</Form.Label>
      <Form.Control
       type="text"
       placeholder="Name"
       value={name}
       onChange={(e) => handleChange("name", e.target.value.toUpperCase())}
       required
      />
     </Form.Group>
    </Col>
   </Row>

   <Row>
    <Col>
     <Form.Group controlId="type">
      <Form.Label>
       Type<span>*</span>
      </Form.Label>
      <Form.Select
       value={type}
       onChange={(e) => handleChange("type", e.target.value)}
       required
      >
       <option value="">{defaultOptions}</option>
       <option value="outlet">Outlet</option>
       <option value="khalid kitchen">Khalid Kitchen</option>
       <option value="office">Management</option>
      </Form.Select>
     </Form.Group>
    </Col>
   </Row>
  </Form>
 );
};

// Validasi props menggunakan PropTypes
OutletForm.propTypes = {
 formData: PropTypes.shape({
  outletID: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.string,
  address: PropTypes.string,
 }),
 onChange: PropTypes.func.isRequired,
 onSubmit: PropTypes.func.isRequired,
 isEditMode: PropTypes.bool,
 defaultOptions: PropTypes.string,
};

export default OutletForm;
