export const updateLocalStorageSettings = (page, key, value) => {
 // Ambil data settings dari localStorage
 const settings = JSON.parse(localStorage.getItem("settings")) || {};

 // Buat atau perbarui page dan key yang diberikan
 const updatedSettings = {
  ...settings,
  [page]: {
   ...settings[page],
   [key]: value,
  },
 };

 // Simpan settings yang sudah diperbarui ke localStorage
 localStorage.setItem("settings", JSON.stringify(updatedSettings));
};

export const getLocalStorageSettings = (page, key) => {
 const settings = JSON.parse(localStorage.getItem("settings")) || {};
 // Cek apakah ada page dan key yang diberikan
 if (!settings[page] || !settings[page][key]) return null;
 //console.log(`settings.${[page]}.${key}:${settings[page]?.[key]}`);
 return settings[page]?.[key];
};
