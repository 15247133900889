import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Row, Col, FloatingLabel, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import FormContainer from "../../components/FormContainer";
import { register } from "../../actions/userActions";
import { LandingPageBgImage } from "../../components/BackgroundImage";

const RegisterView = ({ location, history }) => {
 const [employeeID, setEmployeeID] = useState("");
 const [name, setName] = useState("");
 const [email, setEmail] = useState("");
 const [password, setPassword] = useState("");
 const [confirmPassword, setConfirmPassword] = useState("");
 const [message, setMessage] = useState(null);

 const dispatch = useDispatch();

 const userRegister = useSelector((state) => state.userRegister);
 const { loading, error, userInfo } = userRegister;

 const redirect = location.search ? location.search.split("=")[1] : "/";

 useEffect(() => {
  if (userInfo) {
   history.push(redirect);
  }
 }, [history, userInfo, redirect]);

 const submitHandler = (e) => {
  e.preventDefault();
  if (password !== confirmPassword) {
   setMessage("Passwords do not match");
  } else {
   dispatch(register(employeeID, name, email, password));
  }
 };

 return (
  <>
   <LandingPageBgImage>
    <FormContainer>
     {/*eslint-disable-next-line*/}
     <h1></h1>
     {message && <Message variant="danger">{message}</Message>}
     {error && <Message variant="danger">{error}</Message>}
     {loading && <Loader />}
     <Form onSubmit={submitHandler}>
      <FloatingLabel
       controlId="floatingInput"
       label="Employee ID"
       className="mb-3"
      >
       <Form.Control
        type="number"
        placeholder="Enter employee ID"
        value={employeeID}
        onChange={(e) => setEmployeeID(e.target.value)}
        style={{ borderRadius: 5, width: "100%" }}
        onWheel={(e) => e.target.blur()}
       />
      </FloatingLabel>

      <FloatingLabel
       controlId="floatingInput"
       label="Full Name"
       className="mb-3"
      >
       <Form.Control
        type="text"
        placeholder="Enter name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        style={{ borderRadius: 5, width: "100%" }}
       />
      </FloatingLabel>

      <FloatingLabel
       controlId="floatingInput"
       label="Email address"
       className="mb-3"
      >
       <Form.Control
        type="email"
        placeholder="name@example.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={{ borderRadius: 5, width: "100%" }}
       />
      </FloatingLabel>

      <FloatingLabel
       controlId="floatingInput"
       label="Password"
       className="mb-3"
      >
       <Form.Control
        type="password"
        placeholder="Enter password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={{ borderRadius: 5, width: "100%" }}
       />
      </FloatingLabel>

      <FloatingLabel
       controlId="floatingInput"
       label="Confirm Password"
       className="mb-3"
      >
       <Form.Control
        type="password"
        placeholder="Confirm password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        style={{ borderRadius: 5, width: "100%" }}
       />
      </FloatingLabel>

      <Button type="submit" variant="danger" style={{ width: "100%" }}>
       Register
      </Button>
     </Form>

     <Row className="py-3">
      <Col style={{ justifyContent: "center", display: "flex" }}>
       Already have an account?{" "}
       <Link to={redirect ? `/login?redirect=${redirect}` : "/login"}>
        <strong>Login</strong>
       </Link>
      </Col>
     </Row>
    </FormContainer>
   </LandingPageBgImage>
  </>
 );
};

export default RegisterView;
