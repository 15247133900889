import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { getTimeAgo } from "../../utils/utils";

const CardFooterLastUpdate = ({
 employee,
 className = "d-flex justify-content-between",
}) => {
 if (!employee) return "";

 return (
  <Card.Footer className={className}>
   <Row>
    <Col>
     <small className="text-muted" style={{ fontSize: "0.75em" }}>
      {employee.createdAt &&
       `Updated ${getTimeAgo(employee.updatedAt || employee.createdAt)}`}
     </small>
    </Col>
   </Row>
   <Row>
    <Col>
     <small className="text-muted" style={{ fontSize: "0.75em" }}>
      {employee.lastSeenAt && getTimeAgo(employee.lastSeenAt, true)}
     </small>
    </Col>
   </Row>
  </Card.Footer>
 );
};

export default CardFooterLastUpdate;
