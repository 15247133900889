import React from "react";
import { Row, Col } from "react-bootstrap";
import IconOutlet from "../IconOutlet.jsx";
import { capitalizeEachWord } from "../../utils/utils.jsx";

const Outlet = ({ outlet, className = "justify-content-md-center" }) => {
 if (!outlet) return "";

 return (
  <Row className={className}>
   <Col md="auto" className="d-flex justify-content-center">
    <IconOutlet outlet={outlet} />
    <strong>{capitalizeEachWord(outlet)}</strong>
   </Col>
  </Row>
 );
};

export default Outlet;
