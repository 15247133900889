import React from "react";
import Loader from "./Loader"; // Pastikan sudah ada komponen Loader
import Message from "./Message"; // Pastikan sudah ada komponen Message

const LoadingErrorHandler = ({ loading, error, children }) => {
 if (loading) {
  return <Loader />;
 }

 if (error) {
  return <Message variant="danger">{error}</Message>;
 }

 if (!children) return null;

 return <>{children}</>;
};

export default LoadingErrorHandler;
