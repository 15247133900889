import React from "react";
import PropTypes from "prop-types";
import { Col, Form } from "react-bootstrap";
import { capitalizeEachWord } from "../../utils/utils";

const ReusableFormSelect = ({
 formLabel = "",
 controlId = "",
 value = "",
 onChangeFn = () => {},
 options = [],
 disabled = false,
 defaultLabel = false,
 customLabel = "",
 customStyle = {},
 className = "",
 optionValueField = "", // Default key for option value
}) => {
 //console.log(`options:${options}`);
 if (!options?.length) {
  return null; // Return null if no options or controlId is provided
 }

 const getRegions = () => {
  if (!options?.length) return "";

  const uniqueVal = [...new Set(options)];
  return uniqueVal?.map((option) => {
   if (!option) return "";

   return (
    <option key={option} value={option}>
     {capitalizeEachWord(option)}
    </option>
   );
  });
 };

 return (
  <Col md="auto">
   <Form.Group controlId={controlId || formLabel}>
    {formLabel || controlId ? (
     <Form.Label>{formLabel || controlId}</Form.Label>
    ) : null}
    <Form.Select
     className={className}
     value={value}
     onChange={onChangeFn}
     style={{
      color: "white",
      backgroundColor: disabled ? "#ef7982" : "#dc3545",
      ...customStyle, // Allows overriding inline styles
     }}
     disabled={disabled}
    >
     {defaultLabel && <option value="">{customLabel || "Select..."}</option>}
     {!optionValueField && getRegions()}
    </Form.Select>
   </Form.Group>
  </Col>
 );
};

ReusableFormSelect.propTypes = {
 formLabel: PropTypes.string,
 controlId: PropTypes.string,
 value: PropTypes.string,
 onChange: PropTypes.func,
 options: PropTypes.array,
 disabled: PropTypes.bool,
 defaultLabel: PropTypes.bool,
 customLabel: PropTypes.string,
 customStyle: PropTypes.object,
 className: PropTypes.string,
 optionValueField: PropTypes.string,
 optionLabelField: PropTypes.string,
};

export default ReusableFormSelect;
