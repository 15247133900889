import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { getUserDetails, updateUserProfile } from "../actions/userActions";
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";
import { stringIncludes } from "../utils/utils";
import { logout } from "../actions/userActions";
import dayjs from "dayjs";

const ProfileView = ({ history }) => {
 const [email, setEmail] = useState("");
 const [password, setPassword] = useState("");
 const [confirmPassword, setConfirmPassword] = useState("");
 const [message, setMessage] = useState(null);
 const [isDisabled, setIsDisabled] = useState(true);

 const toggleDisabled = () => {
  setIsDisabled(!isDisabled);
 };

 const dispatch = useDispatch();

 const userDetails = useSelector((state) => state.userDetails);
 const { loading, error, user } = userDetails;

 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;

 const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
 const { success } = userUpdateProfile;
 //console.log(`userInfo: ${JSON.stringify(userInfo)}`);
 useEffect(() => {
  if ((!userLoading && !userInfo) || stringIncludes(error, "not authorized")) {
   dispatch(logout());
   history.push("/login");
  }

  if (!user || !user.name || success) {
   if (isDisabled) {
    dispatch({ type: USER_UPDATE_PROFILE_RESET });
    dispatch(getUserDetails("profile"));
    setIsDisabled(true);
   } else {
    setIsDisabled(true);
   }
  } else {
   setEmail(user.email);
  }
 }, [
  dispatch,
  history,
  userInfo,
  user,
  success,
  isDisabled,
  userLoading,
  error,
 ]);

 const submitHandler = (e) => {
  e.preventDefault();
  if (password !== confirmPassword) {
   setMessage("Passwords do not match");
  } else {
   dispatch(updateUserProfile({ id: user._id, email, password }));
  }
 };

 return (
  <Row>
   <Col md={3}>
    <h3>Profile</h3>
    {message && <Message variant="danger">{message}</Message>}
    {success && <Message variant="success">Profile Updated</Message>}
    {loading ? (
     <Loader />
    ) : error ? (
     <Message variant="danger">{error}</Message>
    ) : (
     <>
      <Form.Label>
       {"User last updated at "}
       {user.updatedAt && dayjs(user.updatedAt).format("DD-MMM-YYYY HH:mm wib")}
      </Form.Label>
      <Form.Label>
       {"User created at "}
       {user.createdAt && dayjs(user.createdAt).format("DD-MMM-YYYY HH:mm wib")}
      </Form.Label>
      <Form onSubmit={submitHandler}>
       <Form.Group controlId="employeeID">
        <Form.Label className="form-label">Employee ID</Form.Label>
        <Form.Control
         type="employeeID"
         placeholder="Your employee ID"
         value={user.employeeID}
         disabled
        ></Form.Control>
       </Form.Group>

       <Form.Group controlId="name">
        <Form.Label>Name</Form.Label>
        <Form.Control
         type="name"
         placeholder="Your full name"
         value={user.name}
         disabled
        ></Form.Control>
       </Form.Group>

       <Form.Group controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
         type="email"
         placeholder="Enter email"
         value={email}
         onChange={(e) => setEmail(e.target.value)}
         disabled={isDisabled}
        ></Form.Control>
       </Form.Group>

       <Form.Group controlId="password">
        <Form.Label>New Password</Form.Label>
        <Form.Control
         type="password"
         placeholder="Enter new password"
         onChange={(e) => setPassword(e.target.value)}
         disabled={isDisabled}
        ></Form.Control>
       </Form.Group>

       <Form.Group controlId="confirmPassword">
        <Form.Label>Confirm New Password</Form.Label>
        <Form.Control
         type="password"
         placeholder="Confirm new password"
         onChange={(e) => setConfirmPassword(e.target.value)}
         disabled={isDisabled}
        ></Form.Control>
       </Form.Group>
       <Button
        type="button"
        variant={isDisabled ? "light" : "secondary"}
        onClick={toggleDisabled}
        className="mt-3"
       >
        {isDisabled ? "Edit" : "Cancel"}
       </Button>
       {"  "}
       {!isDisabled && (
        <Button
         type="submit"
         variant="danger"
         disabled={isDisabled}
         className="mt-3"
        >
         Apply
        </Button>
       )}
      </Form>
     </>
    )}
   </Col>
  </Row>
 );
};

export default ProfileView;
