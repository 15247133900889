import React from "react";
import { Row, Col } from "react-bootstrap";
import { countActiveEmployee, countStatusEmployee } from "../../utils/utils";
import verifiedIcon from "../../Assets/icon/verified.png";

const EmployeeCountByStatus = ({ employee }) => {
 if (!verifiedIcon) return "";

 return (
  <Col style={{ fontSize: "80%" }}>
   <Row>
    <Col md="auto">
     <i
      className="fas fa-user"
      style={{ color: "green", paddingRight: "2px" }}
     ></i>
     <strong style={{ paddingRight: "2px" }}>{`Active:${countActiveEmployee(
      employee,
      true
     )} | `}</strong>
     <i
      className="fas fa-sign-out-alt"
      style={{ color: "red", paddingRight: "2px" }}
     ></i>
     <strong>{`Resigned:${countActiveEmployee(employee, false)}`}</strong>
    </Col>
   </Row>
   <Row>
    <Col md="auto">
     <img
      alt="Verified"
      src={verifiedIcon}
      style={{ width: "18px", height: "18px" }}
     />
     <strong style={{ paddingRight: "2px" }}>{`Tetap:${countStatusEmployee(
      employee,
      true
     )} | `}</strong>
     <i
      className="fas fa-user-clock"
      style={{ color: "gray", paddingRight: "2px" }}
     ></i>
     <strong>{`Probation:${countStatusEmployee(employee, false)}`}</strong>
    </Col>
   </Row>
  </Col>
 );
};

export default EmployeeCountByStatus;
