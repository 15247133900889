import {
 EMPLOYEE_LIST_REQUEST,
 EMPLOYEE_LIST_SUCCESS,
 EMPLOYEE_LIST_ERROR,
 EMPLOYEE_ADD_ERROR,
 EMPLOYEE_ADD_REQUEST,
 EMPLOYEE_ADD_SUCCESS,
 EMPLOYEE_DELETE_ERROR,
 EMPLOYEE_DELETE_REQUEST,
 EMPLOYEE_DELETE_SUCCESS,
 EMPLOYEE_UPDATE_ERROR,
 EMPLOYEE_UPDATE_REQUEST,
 EMPLOYEE_UPDATE_SUCCESS,
 EMPLOYEE_DETAILS_REQUEST,
 EMPLOYEE_DETAILS_SUCCESS,
 EMPLOYEE_DETAILS_ERROR,
 EMPLOYEE_ROOM_NO_REQUEST,
 EMPLOYEE_ROOM_NO_SUCCESS,
 EMPLOYEE_ROOM_NO_ERROR,
} from "../constants/employeeConstant";
import axios from "axios";
import { getAxiosTargetPath } from "../utils/utils";
import { logout } from "./userActions";
import store from "../store";

export const getEmployees = async ({ queryKey }) => {
 const [, { keyword, pageNumber, outlet }] = queryKey;

 const {
  userLogin: { userInfo },
 } = store.getState();

 const config = {
  headers: {
   Authorization: `Bearer ${userInfo?.token}`,
  },
 };
 const axiosPath = getAxiosTargetPath(
  `/employee/all?keyword=${keyword}&pageNumber=${pageNumber}&outlet=${outlet}`
 );
 const { data } = await axios.get(axiosPath, config);
 return data;
};

export const listEmployees =
 (keyword = "", pageNumber = "", outlet = "") =>
 async (dispatch, getState) => {
  try {
   dispatch({ type: EMPLOYEE_LIST_REQUEST });
   const {
    userLogin: { userInfo },
   } = getState();
   const config = {
    headers: {
     Authorization: `Bearer ${userInfo?.token}`,
    },
   };
   const axiosPath = getAxiosTargetPath(
    `/employee/all?keyword=${keyword}&pageNumber=${pageNumber}&outlet=${outlet}`
   );
   const { data } = await axios.get(axiosPath, config);
   dispatch({
    type: EMPLOYEE_LIST_SUCCESS,
    payload: data,
   });
  } catch (error) {
   const message =
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message;
   if (message === "Not authorized, token failed") {
    dispatch(logout());
   }
   dispatch({
    type: EMPLOYEE_LIST_ERROR,
    payload: message,
   });
  }
 };

export const addEmployee = (employee) => async (dispatch, getState) => {
 try {
  dispatch({ type: EMPLOYEE_ADD_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo?.token}`,
   },
  };

  const { data } = await axios.post(`/employee/addEmployee`, employee, config);

  dispatch({
   type: EMPLOYEE_ADD_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: EMPLOYEE_ADD_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const getEmployeeDetails = (id) => async (dispatch, getState) => {
 try {
  dispatch({ type: EMPLOYEE_DETAILS_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo?.token}`,
   },
  };

  const { data } = await axios.get(`/employee/${id}`, config);

  dispatch({
   type: EMPLOYEE_DETAILS_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: EMPLOYEE_DETAILS_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const getEmployeeDetailsByEmployeeId =
 (employeeID) => async (dispatch, getState) => {
  try {
   dispatch({ type: EMPLOYEE_DETAILS_REQUEST });
   const {
    userLogin: { userInfo },
   } = getState();
   const config = {
    headers: {
     Authorization: `Bearer ${userInfo?.token}`,
    },
   };

   const { data } = await axios.get(`/employee/user/${employeeID}`, config);

   dispatch({
    type: EMPLOYEE_DETAILS_SUCCESS,
    payload: data,
   });
  } catch (error) {
   dispatch({
    type: EMPLOYEE_DETAILS_ERROR,
    payload:
     error.response && error.response.data.message
      ? error.response.data.message
      : error.message,
   });
  }
 };

export const updateEmployee = (employee) => async (dispatch, getState) => {
 try {
  dispatch({ type: EMPLOYEE_UPDATE_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo?.token}`,
   },
  };
  const { data } = await axios.put(
   `/employee/${employee._id}`,
   employee,
   config
  );

  dispatch({
   type: EMPLOYEE_UPDATE_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: EMPLOYEE_UPDATE_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const deleteEmployee = (id) => async (dispatch, getState) => {
 try {
  dispatch({ type: EMPLOYEE_DELETE_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo?.token}`,
   },
  };

  const { data } = await axios.delete(`/employee/${id}`, config);

  dispatch({
   type: EMPLOYEE_DELETE_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: EMPLOYEE_DELETE_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const getEmployeesByRoomNo = (roomNo) => async (dispatch, getState) => {
 try {
  dispatch({ type: EMPLOYEE_ROOM_NO_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo?.token}`,
   },
  };

  const { data } = await axios.get(`/employee/room/${roomNo}`, config);
  dispatch({
   type: EMPLOYEE_ROOM_NO_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: EMPLOYEE_ROOM_NO_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};

export const getAllEmployeesAnnualLeaveFromSheets =
 () => async (dispatch, getState) => {
  try {
   dispatch({ type: EMPLOYEE_DETAILS_REQUEST });
   const {
    userLogin: { userInfo },
   } = getState();
   const config = {
    headers: {
     Authorization: `Bearer ${userInfo?.token}`,
    },
   };

   const { data } = await axios.get(`/employee/leave/update`, config);

   dispatch({
    type: EMPLOYEE_DETAILS_SUCCESS,
    payload: data,
   });
  } catch (error) {
   dispatch({
    type: EMPLOYEE_DETAILS_ERROR,
    payload:
     error.response && error.response.data.message
      ? error.response.data.message
      : error.message,
   });
  }
 };

export const deleteEmployeeSchedule = (id) => async (dispatch, getState) => {
 try {
  dispatch({ type: EMPLOYEE_DELETE_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo?.token}`,
   },
  };

  const { data } = await axios.delete(`/employee/${id}/deleteSchedule`, config);

  dispatch({
   type: EMPLOYEE_DELETE_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: EMPLOYEE_DELETE_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};
