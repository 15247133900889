import dayjs from "dayjs";

function getYearNow() {
 return dayjs().year();
}
function getMonthNow() {
 return dayjs().month() + 1;
}

export { getYearNow, getMonthNow };
