import React from "react";

const DownloadButton = ({
 label = "Download",
 image = null,
 altIcon = "alt",
 paddingRight = "2px",
 width = "24px",
 height = "28px",
 onClickFn = () => {},
}) => {
 return (
  <div>
   <button className="button" onClick={onClickFn}>
    {image && (
     <img
      src={image}
      alt={altIcon}
      style={{ width: width, height: height, paddingRight: paddingRight }}
     />
    )}
    {label}
   </button>
  </div>
 );
};

export default DownloadButton;
