import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

const Popup = ({
 show,
 onHide = () => {},
 title,
 children,
 onApply = () => {},
 applyLabel = "Apply",
 cancelLabel = "Cancel",
}) => {
 return (
  <Modal show={show} onHide={onHide}>
   <Modal.Header closeButton>
    <Modal.Title>{title}</Modal.Title>
   </Modal.Header>
   <Modal.Body>{children}</Modal.Body>
   <Modal.Footer className="modal-footer">
    <Button variant="secondary" onClick={onHide}>
     {cancelLabel}
    </Button>
    <Button variant="danger" onClick={onApply}>
     {applyLabel}
    </Button>
   </Modal.Footer>
  </Modal>
 );
};

Popup.propTypes = {
 show: PropTypes.bool.isRequired, // Wajib boolean, apakah modal ditampilkan
 onHide: PropTypes.func.isRequired, // Wajib fungsi, menutup modal
 title: PropTypes.string.isRequired, // Wajib string, judul modal
 children: PropTypes.node, // Elemen React atau teks yang ditampilkan di modal body
 onApply: PropTypes.func, // Fungsi yang dijalankan saat tombol "Apply" diklik
 applyLabel: PropTypes.string, // Label untuk tombol "Apply"
 cancelLabel: PropTypes.string, // Label untuk tombol "Cancel"
};

export default Popup;
