import React, { useState, useEffect } from "react";
import { Form, Row, FloatingLabel, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import FormContainer from "../../components/FormContainer";
import { forgotPassword } from "../../actions/userActions";
import { LandingPageBgImage } from "../../components/BackgroundImage";
import GoBackButton from "../../components/GoBackButton";
import { Link } from "react-router-dom";

const ForgotPasswordView = ({ location, history }) => {
 const [email, setEmail] = useState("");

 const dispatch = useDispatch();

 const userLogin = useSelector((state) => state.userLogin);
 const { loading, error, userInfo } = userLogin;

 const redirect = location.search ? location.search.split("=")[1] : "/";

 useEffect(() => {
  if (userInfo) {
   history.push(redirect);
  }
 }, [history, userInfo, redirect]);

 const submitHandler = (e) => {
  e.preventDefault();
  if (email === "") {
   alert("Please enter your email!");
   return;
  }
  dispatch(forgotPassword(email));
  alert(
   `Email sent to ${email}, Please check your email to reset your password`
  );
  history.push("/login");
 };

 return (
  <>
   <LandingPageBgImage>
    <FormContainer>
     <h4 style={{ marginTop: "40px", marginBottom: "10px" }}>
      Forgot Password
     </h4>
     {error && <Message variant="danger">{error}</Message>}
     {loading && <Loader />}
     <Form>
      <FloatingLabel
       controlId="floatingInput"
       label="Email address"
       className="mb-3"
      >
       <Form.Control
        type="email"
        placeholder="name@example.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={{ borderRadius: 5, width: "100%" }}
       />
      </FloatingLabel>
      <Button
       type="submit"
       variant="danger"
       style={{ width: "100%" }}
       onClick={submitHandler}
       className="mb-3"
      >
       Reset Password
      </Button>

      <Link to="/login">
       <GoBackButton
        type="button" // Set type to "button"
        paddingLeft="0px"
       ></GoBackButton>
      </Link>
      <Row className="mb-3"></Row>
     </Form>
    </FormContainer>
   </LandingPageBgImage>
  </>
 );
};

export default ForgotPasswordView;
