import React from "react";
import dayjs from "dayjs"; // Pastikan dayjs sudah diinstall: npm install dayjs
import { Row, Col } from "react-bootstrap";
import { capitalizeEachWord } from "../../utils/utils";

const Greeting = ({ userInfo }) => {
 const currentHour = dayjs().hour(); // Ambil jam saat ini (0 - 23)

 // Tentukan pesan berdasarkan waktu
 let greetingMessage;
 if (currentHour >= 5 && currentHour < 12) {
  greetingMessage = "Good Morning";
 } else if (currentHour >= 12 && currentHour < 17) {
  greetingMessage = "Good Afternoon";
 } else {
  greetingMessage = "Good Evening";
 }

 return (
  <>
   <Row className="xs">
    <Col md="auto">
     <h3 className="p-0">{`${greetingMessage}, ${
      userInfo?.name ? capitalizeEachWord(userInfo?.name) : "Guest"
     }`}</h3>
    </Col>
   </Row>
   <Row className="xs">
    <Col md="auto">
     <p className="p-0">Welcome to HRIS Sinar Utama Mie Ayam Setiap Hari</p>
    </Col>
   </Row>
  </>
 );
};

export default Greeting;
