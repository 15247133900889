import React, { useMemo } from "react";
import {
 getColumn,
 getDateResignColumn,
 getIdNameColumns,
 getOutletColumn,
 getAge,
 getNumberColumn,
} from "../../utils/utils";
import ReusableTable from "../../components/ReusableTable"; // Import the reusable table
import useFilteredEmployees from "../../components/useFilteredEmployees";

const EmployeeTableAgeView = ({
 isActiveEmployee,
 employees,
 error,
 filterText,
 setFilterText,
 outlet,
}) => {
 const columns = useMemo(
  () => [
   ...getNumberColumn(),
   ...getIdNameColumns(),
   ...getOutletColumn(outlet),
   getColumn("Position", "position", "left"),
   getColumn("Status", "status"),
   ...getDateResignColumn(isActiveEmployee),
   getColumn("Place of Birth", "placeOfBirth"),
   getColumn("Date of Birth", "dateOfBirth", "right", false),
   getColumn(
    "Age",
    "dateOfBirth",
    "right",
    false,
    (item) => getAge(item, false),
    "age"
   ),
  ],
  [isActiveEmployee, outlet]
 );

 const data = useFilteredEmployees(employees, isActiveEmployee);

 return (
  <div>
   <ReusableTable
    columns={columns}
    data={data}
    error={error}
    filterText={filterText}
    setFilterText={setFilterText}
    isActiveEmployee={isActiveEmployee}
   />
  </div>
 );
};

export default EmployeeTableAgeView;
