import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col, Button } from "react-bootstrap";
import dayjs from "dayjs";
import GoBackButton from "../components/GoBackButton";
import { getAge, getWorkDuration } from "../utils/utils";
import { addEmployee } from "../actions/employeeActions";
import { stringIncludes } from "../utils/utils";
import Loader from "../components/Loader";
import Message from "../components/Message.jsx";
import { logout } from "../actions/userActions";
import { employeeTabs } from "../data/etc";
import Tab from "../components/Tab";
import FormSelect from "../components/select/FormSelect";
import { listBanks } from "../actions/bankActions";
import { getAllStatus } from "../actions/statusActions";
import { getAllPosition } from "../actions/positionActions";
import { getAllOutlet } from "../actions/outletActions";

const AddEmployeeView = ({ match, history }) => {
 const [tab, setTab] = useState("work");

 const [employeeID, setEmployeeID] = useState("");
 const [name, setName] = useState("");
 const [outlet, setOutlet] = useState("");
 const [ktpID, setKtpID] = useState("");
 const [addressOfKTP, setAddressOfKTP] = useState("");
 const [address, setAddress] = useState("");

 const [status, setStatus] = useState("");
 const [position, setPosition] = useState("");
 const [sex, setSex] = useState("");
 const [bpjsKesehatan, setBpjsKesehatan] = useState("");
 const [bpjsKesehatanDescription, setBpjsKesehatanDescription] = useState("");
 const [bpjsKetenagakerjaan, setBpjsKetenagakerjaan] = useState("");
 const [bpjsKetenagakerjaanDescription, setBpjsKetenagakerjaanDescription] =
  useState("");

 const [placeOfBirth, setPlaceOfBirth] = useState("");
 const [dateOfBirth, setDateOfBirth] = useState(null);
 const [dateIn, setDateIn] = useState(null);
 const [dateResign, setDateResign] = useState(null);
 const [phone, setPhone] = useState("");
 const [email, setEmail] = useState("");

 const [clockIn, setClockIn] = useState(null);
 const [clockIn2, setClockIn2] = useState(null);
 const [clockIn3, setClockIn3] = useState(null);
 const [reasonResign, setReasonResign] = useState(null);
 const [datePermanent, setDatePermanent] = useState(null);
 const [bank, setBank] = useState("");
 const [bankAccount, setBankAccount] = useState("");
 const [motherName, setMotherName] = useState("");
 const [positionChange, setPositionChange] = useState("");
 const [kkID, setKkID] = useState("");
 const [npwpID, setNpwpID] = useState("");
 const [ukuranBaju, setUkuranBaju] = useState("");
 const [education, setEducation] = useState("");
 const [educationMajor, setEducationMajor] = useState("");
 const [university, setUniversity] = useState("");
 const [dateGraduation, setDateGraduation] = useState(null);
 const [religion, setReligion] = useState("");
 const [emergencyName, setEmergencyName] = useState("");
 const [emergencyRelation, setEmergencyRelation] = useState("");
 const [emergencyPhone, setEmergencyPhone] = useState("");
 const [grade, setGrade] = useState(0);
 const [maritalStatus, setMaritalStatus] = useState("");
 const [lateInTolerance, setLateInTolerance] = useState("");

 const dispatch = useDispatch();
 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;
 const employeeAdd = useSelector((state) => state.employeeAdd);
 const { loading, error, success } = employeeAdd;

 const bankList = useSelector((state) => state.bankList);
 const { loading: loadingBank, error: errorBank, banks } = bankList;

 const statusList = useSelector((state) => state.statusList);
 const {
  loading: loadingAllStatus,
  error: errorAllStatus,
  status: allStatus,
 } = statusList;

 const positionList = useSelector((state) => state.positionList);
 const {
  loading: loadingPositions,
  error: errorPositions,
  positions,
 } = positionList;

 const outletList = useSelector((state) => state.outletList);
 const { loading: loadingOutlets, error: errorOutlets, outlets } = outletList;

 //const banksMemo = useMemo(
 // () => {
 //  if (!loadingBank && !banks.length) dispatch(listBanks());
 //  if (banks) return banks;
 // }, // eslint-disable-next-line
 // []
 //);

 useEffect(() => {
  if ((!userLoading && !userInfo) || stringIncludes(error, "not authorized")) {
   dispatch(logout());
   history.push("/login");
  }
  if (!userInfo?.isAdmin) {
   history.push("/");
  }
  if (!loadingBank && Array.isArray(banks) && !banks.length) {
   dispatch(listBanks());
  }
  //console.log( banks );
  if (!loadingAllStatus && Array.isArray(allStatus) && !allStatus.length) {
   dispatch(getAllStatus());
  }
  //console.log(allStatus);
  if (!loadingPositions && Array.isArray(positions) && !positions.length) {
   dispatch(getAllPosition());
  }
  //console.log(positions);
  if (!loadingOutlets && Array.isArray(outlets) && !outlets.length) {
   dispatch(getAllOutlet());
  }
  //console.log(outlets);
  if (success) {
   history.push("/employeeList");
  } // eslint-disable-next-line
 }, [history, success, banks]);

 const submitHandler = () => {
  dispatch(
   addEmployee({
    employeeID,
    name,
    outlet,
    ktpID,
    addressOfKTP,
    address,
    status,
    position,
    sex,
    bpjsKesehatan,
    bpjsKesehatanDescription,
    bpjsKetenagakerjaan,
    bpjsKetenagakerjaanDescription,
    placeOfBirth,
    dateOfBirth,
    dateIn,
    dateResign,
    phone,
    email,
    clockIn: clockIn ? dayjs(clockIn).toDate() : "",
    clockIn2: clockIn2 ? dayjs(clockIn2).toDate() : "",
    clockIn3: clockIn3 ? dayjs(clockIn3).toDate() : "",
    reasonResign,
    datePermanent,
    bank,
    bankAccount,
    motherName,
    positionChange,
    kkID,
    npwpID,
    ukuranBaju,
    education,
    educationMajor,
    university,
    dateGraduation,
    religion,
    emergencyName,
    emergencyRelation,
    emergencyPhone,
    grade,
    maritalStatus,
    lateInTolerance,
   })
  );
 };

 const defaultOptions = "Select...";

 // Fungsi untuk mengubah tanggal menjadi format YYYY-MM-DD
 const dateForPicker = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD") : "";
 };

 // Fungsi untuk mengubah string tanggal menjadi objek Date
 const dateFromDateString = (dateString) => {
  return dateString ? dayjs(dateString).toDate() : null;
 };

 const getAgeLong = () => {
  return getAge(dateOfBirth);
 };

 const deleteEmployeeScheduleHandler = () => {
  if (window.confirm("Are you sure reseting schedule?")) {
   setClockIn(null);
   setClockIn2(null);
   setClockIn3(null);
  }
 };

 const handleClockInChange = (e) => {
  const timeValue = e.target.value; // waktu dari input, format HH:mm
  const currentDate = dayjs().format("YYYY-MM-DD"); // tanggal saat ini

  // Gabungkan tanggal saat ini dengan waktu input
  const clockInDayjs = dayjs(`${currentDate} ${timeValue}`, "YYYY-MM-DD HH:mm");

  setClockIn(clockInDayjs); // Simpan objek dayjs ke dalam state
 };

 const handleClockInChange2 = (e) => {
  const timeValue = e.target.value; // waktu dari input, format HH:mm
  const currentDate = dayjs().format("YYYY-MM-DD"); // tanggal saat ini

  // Gabungkan tanggal saat ini dengan waktu input
  const clockInDayjs = dayjs(`${currentDate} ${timeValue}`, "YYYY-MM-DD HH:mm");

  setClockIn2(clockInDayjs); // Simpan objek dayjs ke dalam state
 };

 const handleClockInChange3 = (e) => {
  const timeValue = e.target.value; // waktu dari input, format HH:mm
  const currentDate = dayjs().format("YYYY-MM-DD"); // tanggal saat ini

  // Gabungkan tanggal saat ini dengan waktu input
  const clockInDayjs = dayjs(`${currentDate} ${timeValue}`, "YYYY-MM-DD HH:mm");

  setClockIn3(clockInDayjs); // Simpan objek dayjs ke dalam state
 };

 return (
  <>
   {loading ||
   loadingBank ||
   loadingAllStatus ||
   loadingPositions ||
   loadingOutlets ? (
    <Loader />
   ) : error ||
     errorBank ||
     errorAllStatus ||
     errorPositions ||
     errorOutlets ? (
    <Message variant="danger">{error || errorBank}</Message>
   ) : (
    <>
     <Row>
      <Col md="auto">
       <GoBackButton onClick={() => history.goBack()}></GoBackButton>
      </Col>
     </Row>
     <Row className="justify-content-md-center">
      <Col md="auto">
       <h3>{`Add Employee`}</h3>
      </Col>
     </Row>

     <Tab tabs={employeeTabs} tab={tab} setTab={setTab} />

     {stringIncludes(tab, "work") && (
      <>
       <Row className="mt-3">
        <Col md={4}>
         <h4 className="underline">Work</h4>
        </Col>
       </Row>

       <Row>
        <Col md={3}>
         <Form.Group controlId="employeeID">
          <Form.Label>
           Employee ID<span>*</span>
          </Form.Label>
          <Form.Control
           type="number"
           placeholder="Enter employee ID"
           value={employeeID}
           onChange={(e) => setEmployeeID(e.target.value)}
           onWheel={(e) => e.target.blur()}
          ></Form.Control>
         </Form.Group>

         <FormSelect
          formLabel="Status*"
          controlId="status"
          value={status || ""}
          onChange={(e) => setStatus(e.target.value)}
          options={allStatus || ""}
         ></FormSelect>
         <FormSelect
          formLabel="Outlet*"
          controlId="outlet"
          value={outlet || ""}
          onChange={(e) => setOutlet(e.target.value)}
          options={outlets || ""}
         ></FormSelect>
         <FormSelect
          formLabel="Position*"
          controlId="position"
          value={position || ""}
          onChange={(e) => setPosition(e.target.value)}
          options={positions || ""}
         ></FormSelect>
        </Col>
        <Col md={4}>
         <Form.Group controlId="name">
          <Form.Label>
           Full Name<span>*</span>
          </Form.Label>
          <Form.Control
           placeholder="Enter full name"
           value={name}
           onChange={(e) =>
            setName(e.target.value ? e.target.value.toUpperCase() : "")
           }
          ></Form.Control>
         </Form.Group>
         <Form.Group controlId="grade">
          <Form.Label>
           <Row>
            <Col md="auto">Grade / Golongan</Col>
           </Row>
          </Form.Label>
          <Form.Control
           type="number"
           placeholder="Your grade"
           value={grade}
           onChange={(e) =>
            setGrade(e.target.value ? Math.round(e.target.value) : 0)
           }
           onWheel={(e) => e.target.blur()}
          ></Form.Control>
         </Form.Group>

         <Form.Group controlId="dateIn">
          <Row>
           <Col>
            <Form.Label>
             Date Join<span>*</span>
            </Form.Label>
           </Col>
           <Col md={9}>
            <Form.Text className="text-muted">
             {dateIn && `Duration: ${getWorkDuration(dateIn, dateResign)}`}
            </Form.Text>
           </Col>
          </Row>
          <Form.Control
           type="date"
           value={dateForPicker(dateIn)}
           onChange={(e) => setDateIn(dateFromDateString(e.target.value))}
          />
         </Form.Group>

         <Form.Group controlId="datePermanent">
          <Row>
           <Col md="auto">
            <Form.Label>Date Permanent Employee / PKWTT</Form.Label>
           </Col>
           <Col md={9}>
            <Form.Text className="text-muted">
             {datePermanent
              ? `Permanent Duration: ${getWorkDuration(
                 datePermanent,
                 dateResign
                )}`
              : ""}
            </Form.Text>
           </Col>
          </Row>
          <Form.Control
           type="date"
           value={dateForPicker(datePermanent)}
           onChange={(e) =>
            setDatePermanent(dateFromDateString(e.target.value))
           }
           disabled={status !== "TETAP"}
          />
         </Form.Group>
        </Col>
        <Col md={4}>
         <Form.Group controlId="positionChange">
          <Form.Label>Position Change/ Promosi/ Demosi/ Mutasi</Form.Label>
          <Form.Control
           value={positionChange || ""} // Gunakan string kosong jika nilai null
           onChange={(e) => setPositionChange(e.target.value)}
           as="textarea"
           rows={positionChange ? 3 : 1}
          ></Form.Control>
         </Form.Group>
         <Form.Group controlId="dateResign">
          <Row>
           <Col>
            <Form.Label className="text-danger">Date Resign</Form.Label>
           </Col>
          </Row>
          <Form.Control
           type="date"
           value={dateForPicker(dateResign)}
           onChange={(e) => setDateResign(dateFromDateString(e.target.value))}
          />
         </Form.Group>

         <Form.Group controlId="reasonResign">
          <Form.Label className="text-danger">Reason Resign</Form.Label>
          <Form.Control
           placeholder="Enter reason resign"
           value={reasonResign || ""} // Gunakan string kosong jika nilai null
           onChange={(e) => setReasonResign(e.target.value)}
           as="textarea"
           rows={reasonResign ? 2 : 1}
          ></Form.Control>
         </Form.Group>

         <Form.Group controlId="ukuranBaju">
          <Form.Label>Clothes Size / Ukuran Baju</Form.Label>
          <Form.Select
           value={ukuranBaju}
           onChange={(e) => setUkuranBaju(e.target.value)}
          >
           <option value="">{defaultOptions}</option>
           <option value="XS">XS: Extra Small</option>
           <option value="S">S: Small</option>
           <option value="M">M: Medium</option>
           <option value="L">L: Large</option>
           <option value="XL">XL: Extra Large</option>
           <option value="XXL">XXL: Double Extra Large</option>
           <option value="XXXL">XXXL: Triple Extra Large</option>
          </Form.Select>
         </Form.Group>
        </Col>
       </Row>
      </>
     )}

     {stringIncludes(tab, "schedule") && (
      <>
       <Row className="mt-3">
        <Col md={3}>
         <h4 className="underline">Schedule</h4>
        </Col>
       </Row>
       <Row>
        <Col md={2}>
         <Form.Group controlId="clockIn">
          <Form.Label>Shift In</Form.Label>
          <Form.Control
           type="time"
           value={clockIn ? dayjs(clockIn)?.format("HH:mm") : ""}
           onChange={handleClockInChange}
          />
         </Form.Group>
        </Col>
        <Col md={2}>
         <Form.Group controlId="clockIn2">
          <Form.Label>Shift In 2</Form.Label>
          <Form.Control
           type="time"
           value={clockIn2 ? dayjs(clockIn2)?.format("HH:mm") : ""}
           onChange={handleClockInChange2}
          />
         </Form.Group>
        </Col>
        <Col md={2}>
         <Form.Group controlId="clockIn">
          <Form.Label>Shift In 3</Form.Label>
          <Form.Control
           type="time"
           value={clockIn3 ? dayjs(clockIn3)?.format("HH:mm") : ""}
           onChange={handleClockInChange3}
          />
         </Form.Group>
        </Col>
        <Col md={2}>
         {userInfo?.isAdmin && (
          <Button
           variant="danger"
           style={{ justifyContent: "right" }}
           onClick={deleteEmployeeScheduleHandler}
          >
           <i className="fas fa-sync" />
           {" Reset Schedule"}
          </Button>
         )}
        </Col>
       </Row>
       <Row>
        <Col md={4}>
         <Form.Group controlId="lateInTolerance">
          <Form.Label>
           Late In Tolerance / Toleransi Keterlambatan Masuk (Menit)
          </Form.Label>
          <Form.Control
           type="number"
           value={lateInTolerance}
           onChange={(e) => setLateInTolerance(e.target.value)}
           onWheel={(e) => e.target.blur()}
          />
         </Form.Group>
        </Col>
       </Row>
      </>
     )}

     {stringIncludes(tab, "personal") && (
      <>
       <Row className="mt-3">
        <Col md={4}>
         <h4 className="underline">Personal Data</h4>
        </Col>
       </Row>
       <Row>
        <Col md={3}>
         <Form.Group controlId="sex">
          <Form.Label>
           sex<span>*</span>
          </Form.Label>
          <Form.Select value={sex} onChange={(e) => setSex(e.target.value)}>
           <option value="">{defaultOptions}</option>
           <option value="Men">Men</option>
           <option value="Women">Women</option>
          </Form.Select>
         </Form.Group>
         <Form.Group controlId="placeOfBirth">
          <Form.Label>Place of Birth</Form.Label>
          <Form.Control
           placeholder="Enter place of birth"
           value={placeOfBirth}
           onChange={(e) =>
            setPlaceOfBirth(e.target.value ? e.target.value.toUpperCase() : "")
           }
          ></Form.Control>
         </Form.Group>
         <Form.Group controlId="dateOfBirth">
          <Row>
           <Col>
            <Form.Label>Date of Birth </Form.Label>
           </Col>
           <Col md={8}>
            <Form.Text className="text-muted">{getAgeLong()}</Form.Text>
           </Col>
          </Row>
          <Form.Control
           type="date"
           value={dateForPicker(dateOfBirth)}
           onChange={(e) => setDateOfBirth(dateFromDateString(e.target.value))}
          />
         </Form.Group>
        </Col>
        <Col md={3}>
         <Form.Group controlId="religion">
          <Form.Label>Religion / Agama</Form.Label>
          <Form.Select
           value={religion}
           onChange={(e) => setReligion(e.target.value)}
          >
           <option value="">{defaultOptions}</option>
           <option value="ISLAM">Islam</option>
           <option value="PROTESTAN">Kristen Protestan</option>
           <option value="KATOLIK">Kristen Katolik</option>
           <option value="HINDU">Hindu</option>
           <option value="BUDDHA">Buddha</option>
           <option value="KHONGHUCU">Khonghucu</option>
           <option value="LAINNYA">Lainnya</option>
          </Form.Select>
         </Form.Group>
         <Form.Group controlId="maritalStatus">
          <Form.Label>Marital Status / Status Perkawinan</Form.Label>
          <Form.Select
           value={maritalStatus}
           onChange={(e) => setMaritalStatus(e.target.value)}
          >
           <option value="">{defaultOptions}</option>
           <option value="TK">Tidak Kawin</option>
           <option value="K/0">Kawin, belum punya anak</option>
           <option value="K/1">Kawin, anak 1</option>
           <option value="K/2">Kawin, anak 2</option>
           <option value="K/3">Kawin, anak 3</option>
          </Form.Select>
         </Form.Group>
         <Form.Group controlId="motherName">
          <Form.Label>Mother's Full Name/ Nama Lengkap Ibu Kandung</Form.Label>
          <Form.Control
           value={motherName}
           onChange={(e) =>
            setMotherName(e.target.value ? e.target.value.toUpperCase() : "")
           }
          ></Form.Control>
         </Form.Group>
        </Col>
        <Col md={4}>
         <Form.Group controlId="addressOfKTP">
          <Form.Label>Address of KTP</Form.Label>
          <Form.Control
           placeholder="Enter address of KTP"
           value={addressOfKTP || ""} // Gunakan string kosong jika nilai null
           onChange={(e) => setAddressOfKTP(e.target.value)}
           as="textarea"
           rows={2}
          ></Form.Control>
         </Form.Group>
         <Form.Group controlId="address">
          <Form.Label>Address of Domicile</Form.Label>
          <Form.Control
           placeholder="Enter address of domicile"
           value={address || ""} // Gunakan string kosong jika nilai null
           onChange={(e) => setAddress(e.target.value)}
           as="textarea"
           rows={2}
          ></Form.Control>
         </Form.Group>
        </Col>
       </Row>
       <Row className="mt-3">
        <Col md={4}>
         <h4 className="underline">Document</h4>
        </Col>
       </Row>
       <Row>
        <Col md={3}>
         <Form.Group controlId="ktpID">
          <Form.Label>KTP</Form.Label>
          <Form.Control
           type="number"
           placeholder="Enter KTP ID"
           value={ktpID}
           onChange={(e) => setKtpID(e.target.value)}
           onWheel={(e) => e.target.blur()}
          ></Form.Control>
         </Form.Group>
        </Col>
        <Col md={3}>
         <Form.Group controlId="kkID">
          <Form.Label>Kartu Keluarga</Form.Label>
          <Form.Control
           type="number"
           value={kkID}
           onChange={(e) => setKkID(e.target.value)}
           onWheel={(e) => e.target.blur()}
          ></Form.Control>
         </Form.Group>
        </Col>
        <Col md={3}>
         <Form.Group controlId="npwpID">
          <Form.Label>NPWP</Form.Label>
          <Form.Control
           type="number"
           placeholder="Enter NPWP"
           value={npwpID}
           onChange={(e) => setNpwpID(e.target.value)}
           onWheel={(e) => e.target.blur()} // prevent number change on scroll
          ></Form.Control>
         </Form.Group>
        </Col>
       </Row>
      </>
     )}

     {stringIncludes(tab, "contact") && (
      <>
       <Row className="mt-3">
        <Col md={3}>
         <h4 className="underline">Contact</h4>
        </Col>
       </Row>
       <Row>
        <Col md={2}>
         <Form.Group controlId="phone">
          <Row>
           <Col>
            <Form.Label>Phone</Form.Label>
           </Col>
          </Row>
          <Form.Control
           placeholder="Enter phone"
           value={phone}
           onChange={(e) => setPhone(e.target.value)}
          ></Form.Control>
         </Form.Group>
        </Col>
        <Col md={4}>
         <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
           placeholder="Enter email"
           value={email}
           onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
         </Form.Group>
        </Col>
        <Col md={4}></Col>
       </Row>
       <Row className="mt-3">
        <Col md={3}>
         <h4 className="underline">Emergency Contact</h4>
        </Col>
       </Row>
       <Row>
        <Col md={2}>
         <Form.Group controlId="emergencyPhone">
          <Row>
           <Col>
            <Form.Label>Emergency Phone</Form.Label>
           </Col>
          </Row>
          <Form.Control
           placeholder="Enter emergency phone"
           value={emergencyPhone}
           onChange={(e) => setEmergencyPhone(e.target.value)}
          ></Form.Control>
         </Form.Group>
        </Col>
        <Col md={2}>
         <Form.Group controlId="emergencyRelation">
          <Form.Label>Emergency Relation</Form.Label>
          <Form.Select
           value={emergencyRelation}
           onChange={(e) => setEmergencyRelation(e.target.value)}
          >
           <option value="">{defaultOptions}</option>
           <option value="IBU">Ibu</option>
           <option value="AYAH">Ayah</option>
           <option value="KAKAK">Kakak</option>
           <option value="ADIK">Adik</option>
           <option value="ISTRI">Istri</option>
           <option value="SUAMI">Suami</option>
          </Form.Select>
         </Form.Group>
        </Col>
        <Col md={4}>
         <Form.Group controlId="emergencyName">
          <Form.Label>Emergency Full Name</Form.Label>
          <Form.Control
           placeholder="Enter emergency name"
           value={emergencyName}
           onChange={(e) =>
            setEmergencyName(e.target.value ? e.target.value.toUpperCase() : "")
           }
          ></Form.Control>
         </Form.Group>
        </Col>
       </Row>
      </>
     )}

     {stringIncludes(tab, "education") && (
      <>
       <Row className="mt-3">
        <Col md={3}>
         <h4 className="underline">Education</h4>
        </Col>
       </Row>
       <Row>
        <Col md={4}>
         <Form.Group controlId="education">
          <Form.Label>Education</Form.Label>
          <Form.Select
           value={education}
           onChange={(e) => setEducation(e.target.value)}
          >
           <option value="">{defaultOptions}</option>
           <option value="SD">SD</option>
           <option value="SMP">SMP</option>
           <option value="SMA">SMA</option>
           <option value="SMK">SMK</option>
           <option value="D1">D1</option>
           <option value="D2">D2</option>
           <option value="D3">D3</option>
           <option value="D4">D4</option>
           <option value="S1">S1</option>
           <option value="S2">S2</option>
           <option value="S3">S3</option>
          </Form.Select>
         </Form.Group>
         <Form.Group controlId="university">
          <Form.Label>University / School Name</Form.Label>
          <Form.Control
           placeholder="Enter university or school name"
           value={university}
           onChange={(e) =>
            setUniversity(e.target.value ? e.target.value.toUpperCase() : "")
           }
          ></Form.Control>
         </Form.Group>
        </Col>
        <Col md={4}>
         <Form.Group controlId="educationMajor">
          <Form.Label>Major / Jurusan</Form.Label>
          <Form.Control
           placeholder="Enter major or jurusan"
           value={educationMajor}
           onChange={(e) => setEducationMajor(e.target.value)}
          ></Form.Control>
         </Form.Group>
         <Form.Group controlId="dateGraduation">
          <Row>
           <Col md="auto">
            <Form.Label>Date Graduation</Form.Label>
           </Col>
           <Col md="auto">
            <Form.Text className="text-muted">
             {dateGraduation &&
              `${getWorkDuration(dateGraduation, dateResign)} after graduation`}
            </Form.Text>
           </Col>
          </Row>
          <Form.Control
           type="date"
           value={dateForPicker(dateGraduation)}
           onChange={(e) =>
            setDateGraduation(dateFromDateString(e.target.value))
           }
          />
         </Form.Group>
        </Col>
       </Row>
      </>
     )}

     {stringIncludes(tab, "bpjs") && (
      <>
       <Row className="mt-3">
        <Col md={3}>
         <h4 className="underline">BPJS Kesehatan</h4>
        </Col>
       </Row>
       <Row>
        <Col md={3}>
         <Form.Group controlId="bpjsKesehatan">
          <Form.Label>No. BPJS Kesehatan</Form.Label>
          <Form.Control
           type="number"
           placeholder="Enter BPJS Kesehatan"
           value={bpjsKesehatan}
           onChange={(e) => setBpjsKesehatan(e.target.value)}
           onWheel={(e) => e.target.blur()}
          ></Form.Control>
         </Form.Group>
        </Col>
        <Col md={4}>
         <Form.Group controlId="bpjsKesehatanDescription">
          <Form.Label>BPJS Kesehatan Description</Form.Label>
          <Form.Control
           placeholder="Enter BPJS Kesehatan Description"
           value={bpjsKesehatanDescription || ""} // Gunakan string kosong jika nilai null
           onChange={(e) => setBpjsKesehatanDescription(e.target.value)}
           as="textarea"
           rows={4}
          ></Form.Control>
         </Form.Group>
        </Col>
       </Row>
       <Row className="mt-3">
        <Col md={3}>
         <h4 className="underline">BPJS Ketenagakerjaan</h4>
        </Col>
       </Row>
       <Row>
        <Col md={3}>
         <Form.Group controlId="bpjsKetenagakerjaan">
          <Form.Label>No. BPJS Ketenagakerjaan</Form.Label>
          <Form.Control
           type="number"
           placeholder="Enter BPJS Ketenagakerjaan"
           value={bpjsKetenagakerjaan}
           onChange={(e) => setBpjsKetenagakerjaan(e.target.value)}
           onWheel={(e) => e.target.blur()}
          ></Form.Control>
         </Form.Group>
        </Col>
        <Col md={4}>
         <Form.Group controlId="bpjsKetenagakerjaanDescription">
          <Form.Label>BPJS Ketenagakerjaan Description</Form.Label>
          <Form.Control
           placeholder="Enter BPJS Ketenagakerjaan Description"
           value={bpjsKetenagakerjaanDescription || ""} // Gunakan string kosong jika nilai null
           onChange={(e) => setBpjsKetenagakerjaanDescription(e.target.value)}
           as="textarea"
           rows={4}
          ></Form.Control>
         </Form.Group>
        </Col>
       </Row>
      </>
     )}
    </>
   )}
   {stringIncludes(tab, "bank") && (
    <>
     <Row className="mt-3">
      <Col md={3}>
       <h4 className="underline">Bank</h4>
      </Col>
     </Row>
     <Row>
      <FormSelect
       formLabel="Bank Name"
       controlId="bank"
       value={bank || ""}
       onChange={(e) => setBank(e.target.value)}
       options={banks || ""}
      ></FormSelect>
      <Col md={3}>
       <Form.Group controlId="bankAccount">
        <Form.Label>Bank Account / No. Rekening</Form.Label>
        <Form.Control
         type="number"
         value={bankAccount}
         onChange={(e) => setBankAccount(e.target.value)}
         onWheel={(e) => e.target.blur()}
        ></Form.Control>
       </Form.Group>
      </Col>
     </Row>
    </>
   )}
   <Row className="mt-3">
    <Col md={3}>
     {
      <Button
       type="submit"
       variant="danger"
       onClick={() => {
        submitHandler();
       }}
      >
       Add Employee
      </Button>
     }
    </Col>
   </Row>
  </>
 );
};

export default AddEmployeeView;
