import React, { useCallback } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

const DeleteButton = ({ type, item, deleteAction }) => {
 const dispatch = useDispatch();

 const deleteHandler = useCallback(() => {
  if (!item || !type || typeof deleteAction !== "function") {
   console.error("Invalid props passed to DeleteButton");
   return;
  }

  const itemName = item?.name || "Unnamed";

  if (window.confirm(`Delete this ${type} "${itemName}"?`)) {
   dispatch(deleteAction(item));
  }
 }, [item, type, deleteAction, dispatch]);

 return (
  <Button
   variant="danger"
   className="btn-sm"
   onClick={deleteHandler}
   aria-label={`Delete ${type}`}
  >
   <i className="fas fa-trash" aria-hidden="true"></i>
  </Button>
 );
};

// Validasi tipe props
DeleteButton.propTypes = {
 type: PropTypes.string.isRequired, // Harus berupa string
 item: PropTypes.shape({
  name: PropTypes.string, // Opsional, tapi jika ada harus string
 }).isRequired, // Harus berupa objek
 deleteAction: PropTypes.func.isRequired, // Harus berupa fungsi
};

export default DeleteButton;
