import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { stringIncludes } from "../../utils/utils.jsx";
import { logout } from "../../actions/userActions.jsx";
import LoadingErrorHandler from "../../components/LoadingErrorHandler.jsx";
import Greeting from "./Greeting.jsx";
import AnaliticsOverview from "./AnalyticsOverview.jsx";
import {
 getAllEmployeeSummary,
 getEmployeeSummary,
} from "../../actions/employeeSummaryActions.jsx";
import { useQuery } from "@tanstack/react-query";
import { getMonthNow, getYearNow } from "../../utils/time.jsx";
import dayjs from "dayjs";
import { getAllOutlet } from "../../actions/outletActions.jsx";
import FormSelect from "../../components/select/FormSelect.jsx";
import {
 getLocalStorageSettings,
 updateLocalStorageSettings,
} from "../../utils/localStorage.jsx";
import { DatePicker } from "rsuite";

const DashboardView = ({ match, history }) => {
 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;
 const token = userInfo?.token;
 const existingOutletSettings = getLocalStorageSettings("dashboard", "outlet");

 const [outlet, setOutlet] = useState(
  userInfo?.isAdmin || userInfo?.isCompanyLeader
   ? existingOutletSettings ?? userInfo?.outlet ?? "ALL OUTLETS"
   : userInfo?.outlet
 );

 const outletList = useSelector((state) => state.outletList);
 const {
  loading: outletListLoading,
  error: errorOutlets,
  outlets,
 } = outletList;

 const handleOutletChange = (e) => {
  const selectedOutlet = e.target.value;
  setOutlet(selectedOutlet);
  updateLocalStorageSettings("dashboard", "outlet", selectedOutlet);
 };
 const dateNow = dayjs().get("date");
 const defaultDate = dayjs(
  `${getYearNow()}-${dateNow < 2 ? getMonthNow() : getMonthNow() + 1}-01`
 )
  ?.startOf("day")
  .toDate();

 const [date, setDate] = useState(defaultDate);

 const {
  data,
  isLoading: isLoadingEmployeeSummary,
  error: errorQuery,
  refetch: fetchEmployeeSummary,
 } = useQuery({
  queryKey: [
   "getEmployeeSummary",
   { year: dayjs(date)?.get("year"), month: dayjs(date)?.get("month"), outlet },
  ], // Query key as an array
  queryFn: getEmployeeSummary, // Function to fetch data
  enabled: token ? true : false, // Only fetch if token is available
  staleTime: 1000 * 60, // Data considered fresh for 60 seconds (60000 ms)
  refetchInterval: 1000 * 20,
  retry: false, // Disable retries on failure
  refetchOnError: false, // Disable automatic refetch when there's an error
 });

 const employeeSummary = data?.employeeSummary || [];

 const {
  data: dataGetAllEmployeeSummary,
  isLoading: isLoadingGetAllEmployeeSummary,
  error: errorGetAllEmployeeSummary,
  refetch: fetchGetAllEmployeeSummary,
 } = useQuery({
  queryKey: [
   "getAllEmployeeSummary",
   { year: dayjs(date)?.get("year"), month: dayjs(date)?.get("month"), outlet },
  ], // Query key as an array
  queryFn: getAllEmployeeSummary, // Function to fetch data
  enabled: token ? true : false, // Only fetch if token is available
  staleTime: 1000 * 60, // Data considered fresh for 60 seconds (60000 ms)
  refetchInterval: 1000 * 20,
  retry: false, // Disable retries on failure
  refetchOnError: false, // Disable automatic refetch when there's an error
 });
 const employeeSummaries = dataGetAllEmployeeSummary?.employeeSummaries || [];
 const error =
  errorQuery?.response?.data?.message ||
  errorQuery?.message ||
  errorGetAllEmployeeSummary?.response?.data?.message ||
  errorGetAllEmployeeSummary?.message ||
  errorOutlets ||
  "";

 const handleDateChange = (date) => {
  if (!date) {
   setDate(defaultDate);
   return;
  }

  const newDate = dayjs(date)?.add(1, "month");

  setDate(
   dayjs(`${newDate.format("YYYY-MM")}-01`)
    ?.startOf("day")
    ?.toDate()
  );
 };

 const dispatch = useDispatch();
 useEffect(() => {
  if ((!userLoading && !userInfo) || stringIncludes(error, "not authorized")) {
   dispatch(logout());
   history.push("/login");
  }

  if (!outletListLoading && !outlets?.length && !error) {
   dispatch(getAllOutlet());
  }
  if (!isLoadingEmployeeSummary && !isLoadingGetAllEmployeeSummary && !error) {
   // Panggil fungsi query secara manual dan tunggu hingga selesai
   fetchEmployeeSummary({
    queryKey: [
     `getEmployeeSummary-manual-${dayjs(date)?.get("year")}-${dayjs(date)?.get(
      "month"
     )}-${outlet?.name}`,
     {
      month: dayjs(date)?.get("month"),
      year: dayjs(date)?.get("year"),
      outlet: outlet?.name,
     },
    ],
   });
   fetchGetAllEmployeeSummary({
    queryKey: [
     `getAllEmployeeSummary-manual-${dayjs(date)?.get("year")}-${dayjs(
      date
     )?.get("month")}-${outlet?.name}`,
     {
      month: dayjs(date)?.get("month"),
      year: dayjs(date)?.get("year"),
      outlet: outlet?.name,
     },
    ],
   });
  }
  // eslint-disable-next-line
 }, [
  userLoading,
  userInfo,
  history,
  employeeSummary,
  employeeSummaries,
  outlet,
  error,
  date,
 ]);

 return (
  <>
   <Container fluid>
    <Greeting userInfo={userInfo} />
   </Container>
   <Container>
    <Row className="xs">
     {userInfo?.isAdmin || userInfo?.isCompanyLeader ? (
      <FormSelect
       controlId="outlet"
       value={outlet || ""}
       onChange={handleOutletChange}
       options={outlets || ""}
       defaultLabel={false}
       customLabel="ALL OUTLETS"
      />
     ) : (
      <></>
     )}
     <Col>
      <DatePicker
       format="MM-yyyy"
       placeholder={`Select date`}
       onChange={handleDateChange}
       ranges={[]}
       //defaultValue={date ? dayjs(date).startOf("day").toDate() : null} // Ensure this is a dayjs object
      />
     </Col>
    </Row>
   </Container>
   <LoadingErrorHandler
    loading={isLoadingEmployeeSummary || isLoadingGetAllEmployeeSummary}
    error={error}
   >
    <AnaliticsOverview data={employeeSummary} allData={employeeSummaries} />
   </LoadingErrorHandler>
  </>
 );
};

export default DashboardView;
