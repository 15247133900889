import React, { useMemo } from "react";
import {
 getColumn,
 getDateResignColumn,
 getIdNameColumns,
 getNumberColumn,
 getOutletColumn,
} from "../../utils/utils";
import ReusableTable from "../../components/ReusableTable";
import Message from "../../components/Message";
import useFilteredEmployees from "../../components/useFilteredEmployees";

const EmployeeTableBpjsView = ({
 isActiveEmployee,
 employees,
 error,
 filterText,
 setFilterText,
 outlet,
}) => {
 const columns = useMemo(
  () => [
   ...getNumberColumn(),
   ...getIdNameColumns(),
   ...getOutletColumn(outlet),
   getColumn("Status", "status"),
   ...getDateResignColumn(isActiveEmployee),
   getColumn("BPJS Kesehatan", "bpjsKesehatan", false),
   getColumn("Description", "bpjsKesehatanDescription", "left", false),
   getColumn("BPJS Ketenagakerjaan", "bpjsKetenagakerjaan", false),
   getColumn("Description", "bpjsKetenagakerjaanDescription", "left", false),
  ],
  [isActiveEmployee, outlet]
 );

 const data = useFilteredEmployees(employees, isActiveEmployee);

 return (
  <>
   {error ? (
    <Message variant="danger">{error}</Message>
   ) : (
    <ReusableTable
     columns={columns}
     data={data}
     error={error}
     filterText={filterText}
     setFilterText={setFilterText}
     isActiveEmployee={isActiveEmployee}
    />
   )}
  </>
 );
};

export default EmployeeTableBpjsView;
