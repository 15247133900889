import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import InstagramLink from "./InstagramLink.jsx";
import FacebookLink from "./FacebookLink.jsx";
import LinktreeLink from "./LinktreeLink.jsx";

const Footer = () => {
 return (
  <Container>
   <Row>
    <Col className="text-center py-1 ">
     <InstagramLink />
     <LinktreeLink />
     <FacebookLink />
    </Col>
   </Row>
   <Row>
    <Col className="text-center py-1">
     Version 1.0.1 &copy;Sinar Utama Mie Ayam Setiap Hari
    </Col>
   </Row>
  </Container>
 );
};

export default Footer;
