import axios from "axios";
import { getAxiosTargetPath } from "../utils/utils";
import store from "../store";
import { getMonthNow, getYearNow } from "../utils/time";

export const updateEmployeeSummary = async ({ queryKey }) => {
 const [
  ,
  { year = getYearNow(), month = getMonthNow(), keyword = "", outlet },
 ] = queryKey;

 const {
  userLogin: { userInfo },
 } = store.getState();

 const config = {
  headers: {
   Authorization: `Bearer ${userInfo?.token}`,
  },
 };
 const axiosPath = getAxiosTargetPath(
  `/employeeSummary/update?year=${year}&month=${month}&keyword=${keyword}&outlet=${outlet}`
 );
 const { data } = await axios.get(axiosPath, config);
 return data;
};

export const getEmployeeSummary = async ({ queryKey }) => {
 const [
  ,
  { year = getYearNow(), month = getMonthNow(), keyword = "", outlet },
 ] = queryKey;

 const {
  userLogin: { userInfo },
 } = store.getState();

 const config = {
  headers: {
   Authorization: `Bearer ${userInfo?.token}`,
  },
 };
 const axiosPath = getAxiosTargetPath(
  `/employeeSummary/?year=${year}&month=${month}&keyword=${keyword}&outlet=${outlet}`
 );
 const { data } = await axios.get(axiosPath, config);
 return data;
};

export const getLatestEmployeeSummary = async () => {
 const {
  userLogin: { userInfo },
 } = store.getState();

 const config = {
  headers: {
   Authorization: `Bearer ${userInfo?.token}`,
  },
 };
 const axiosPath = getAxiosTargetPath(`/employeeSummary/latest`);
 const { data } = await axios.get(axiosPath, config);
 return data;
};

export const getAllEmployeeSummary = async ({ queryKey }) => {
 const [
  ,
  { year = getYearNow(), month = getMonthNow(), keyword = "", outlet },
 ] = queryKey;

 const {
  userLogin: { userInfo },
 } = store.getState();

 const config = {
  headers: {
   Authorization: `Bearer ${userInfo?.token}`,
  },
 };
 const axiosPath = getAxiosTargetPath(
  `/employeeSummary/all?year=${year}&month=${month}&keyword=${keyword}&outlet=${outlet}`
 );
 const { data } = await axios.get(axiosPath, config);
 return data;
};
