import React from "react";
import { Col } from "react-bootstrap";
import { capitalizeEachWord } from "../../utils/utils.jsx";
import dayjs from "dayjs";

const LatestAttendanceUpdated = ({ attendance }) => {
 if (!attendance) return "";

 return (
  <Col md="auto">
   {attendance?.updatedAt &&
    `Latest attendance updated at: ${dayjs(attendance?.updatedAt).format(
     "YYYY-MM-DD HH:mm:ss wib"
    )} (${attendance?.employeeID} - ${capitalizeEachWord(attendance?.name)} - ${
     attendance?.status
    } - ${dayjs(attendance?.date).format("YYYY-MM-DD")})`}
   {attendance?.adddedByUserEmployeeID &&
    ` updated by ${attendance?.adddedByUserEmployeeID} - ${capitalizeEachWord(
     attendance?.addedByUserName
    )}`}
  </Col>
 );
};

export default LatestAttendanceUpdated;
