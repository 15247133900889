/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message.jsx";
import { addOutlet, getOutlets } from "../../actions/outletActions.jsx";
import { Row, Col, Button } from "react-bootstrap";
import { stringIncludes } from "../../utils/utils.jsx";
import { logout } from "../../actions/userActions.jsx";
import OutletTableView from "./OutletTableView.jsx";
import Popup from "../../components/Popup.jsx";
import OutletForm from "./OutletPopupBody.jsx";
import { useQuery } from "@tanstack/react-query";

const OutletListView = ({ match, history }) => {
 const [showAddOutlet, setShowAddOutlet] = useState(false);
 const [outletData, setOutletData] = useState({
  outletID: 0,
  name: "",
  type: "",
  address: "",
  lateInTolerance: 0,
 });

 const keyword = match.params.keyword;
 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;
 const token = userInfo?.token;

 const outletAdd = useSelector((state) => state.outletAdd);
 const { loading: outletAddLoading, error: outletAddError } = outletAdd;

 const dispatch = useDispatch();

 const {
  data,
  isLoading,
  error: errorQuery,
  refetch: refetchGetOutlets, // Tambahkan refetch di sini
 } = useQuery({
  queryKey: ["outlets", {}], // Query key as an array
  queryFn: getOutlets, // Function to fetch data
  enabled: token ? true : false, // Only fetch if token is available
  staleTime: 1000 * 60, // Data considered fresh for 60 seconds (60000 ms)
  refetchInterval: 1000 * 20,
  retry: false, // Disable retries on failure
 });

 const outlets = data?.outlets || [];
 const error =
  errorQuery?.response?.data?.message ||
  errorQuery?.message ||
  errorQuery ||
  "";
 const loading = isLoading || outletAddLoading;

 console.log(error);

 // eslint-disable-next-line
 useEffect(() => {
  if ((!userLoading && !userInfo) || stringIncludes(error, "not authorized")) {
   dispatch(logout());
   history.push("/login");
  }

  //console.log(outlets);
 }, [userLoading, userInfo, outlets, history]);

 const handleCloseAddOutlet = (event) => {
  if (event) {
   event.preventDefault();
  }

  setShowAddOutlet(false);
  setOutletData(null);
 };

 const handleShowAddOutlet = (event) => {
  if (event) {
   event.preventDefault(); // Prevent the default action
  }

  setShowAddOutlet(true);
 };

 const submitHandler = async (event) => {
  if (event) {
   event.preventDefault(); // Mencegah halaman refresh
  }
  if (!outletData) return;

  await dispatch(addOutlet(outletData));
  refetchGetOutlets(); // Refetch data setelah add outlet berhasil
  setShowAddOutlet(false);
  setOutletData(null);
 };

 return (
  <>
   <Row style={{ justifyContent: "center" }}>
    <Col md="auto">
     <h3>Outlets</h3>
    </Col>
   </Row>

   <Row>
    <Col>
     <Button variant="danger" onClick={() => handleShowAddOutlet()}>
      Add Outlet
     </Button>
    </Col>
   </Row>
   <Row>
    {outletAddError && <Message variant="danger">{outletAddError}</Message>}
    {
     <OutletTableView
      keyword={keyword}
      outlets={outlets}
      loading={loading}
      error={error}
      refetch={refetchGetOutlets}
     />
    }
   </Row>
   <Popup
    show={showAddOutlet}
    onHide={handleCloseAddOutlet}
    title="Add Outlet"
    onApply={submitHandler}
    applyLabel="Save"
    cancelLabel="Cancel"
   >
    <OutletForm
     formData={outletData}
     onChange={(updatedData) => {
      setOutletData(updatedData);
     }}
     onSubmit={submitHandler}
    />
   </Popup>
  </>
 );
};

export default OutletListView;
