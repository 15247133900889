import React from "react";
import { Row, Col } from "react-bootstrap";
import IconShirt from "../IconShirt";
import { WhatsAppLink } from "../WhatsAppLink";
import { EmailLink } from "../Email";

const Contact = ({
 phone,
 email,
 ukuranBaju,
 isWaSquare = false,
 emailColor = "white",
 className = "justify-content-md-center",
}) => {
 if (!phone && !email && !ukuranBaju) return "";

 return (
  <Row className={className}>
   {phone && (
    <Col md="auto">
     <WhatsAppLink phone={phone} isSquare={isWaSquare} />
    </Col>
   )}
   {email && (
    <Col md="auto">
     <EmailLink email={email} color={emailColor} />
    </Col>
   )}
   {ukuranBaju && (
    <Col md="auto" className="d-flex justify-content-center">
     <IconShirt size={ukuranBaju}></IconShirt>
    </Col>
   )}
  </Row>
 );
};

export default Contact;
