import React, { useState, useCallback, useMemo } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { CWidgetStatsA, CWidgetStatsB } from "@coreui/react";
import dayjs from "dayjs";
import { CChart, CChartBar, CChartLine } from "@coreui/react-chartjs";
import {
 getPercentDiff,
 capitalizeEachWord,
 stringIncludes,
 round,
} from "../../utils/utils";
import { CIcon } from "@coreui/icons-react";
import { cilArrowTop, cilArrowBottom } from "@coreui/icons";
import EmployeeSummaryModal from "../../components/EmployeeSummaryModal";

const AnaliticsOverview = ({ data, allData }) => {
 const [modalVisible, setModalVisible] = useState({
  employees: {
   new: false,
   resigned: false,
   probation: false,
   tetap: false,
   kontrak: false,
   onTheJobTraining: false,
   outsourcing: false,
   partTime: false,
  },
 });
 const toggleModal = useCallback((modalName) => {
  setModalVisible((prevState) => {
   // Only update state if the modal visibility actually changes
   const newVisibility = !prevState?.employees[modalName];
   if (newVisibility === prevState?.employees[modalName]) {
    return prevState; // No need to update state
   }

   return {
    employees: {
     ...prevState?.employees,
     [modalName]: newVisibility,
    },
   };
  });
 }, []);

 let monthActiveEmployeesArray = [];
 if (allData) {
  monthActiveEmployeesArray = allData?.map(
   (record) => record?.month && dayjs(record?.month).format("MMM")
  );
 }

 let totalActiveEmployeesArray = [];
 if (allData) {
  totalActiveEmployeesArray = allData?.map(
   (record) => record?.totalActiveEmployees
  );
 }
 const percentDiffActiveEmployees =
  getPercentDiff(totalActiveEmployeesArray) ?? 0;

 let totalResignedEmployeesArray = [];
 if (allData) {
  totalResignedEmployeesArray = allData?.map(
   (record) => record?.totalResignedEmployees
  );
 }
 const percentDiffResignedEmployees =
  getPercentDiff(totalResignedEmployeesArray) ?? 0;
 const latestTotalResignedEmployees =
  totalResignedEmployeesArray?.slice(-1) ?? 0;
 const latestTotalActiveEmployees = totalActiveEmployeesArray?.slice(-1) ?? 0;
 const latestAttendanceRate = round(
  (Number(latestTotalResignedEmployees) /
   (Number(latestTotalActiveEmployees) +
    Number(latestTotalResignedEmployees))) *
   100,
  2
 );

 let turnOverRateArray = [];
 if (allData) {
  turnOverRateArray = allData?.map((record) => {
   if (!record) return 0;

   const resign = Number(record?.totalResignedEmployees);
   const totalEmployees = Number(
    record?.totalActiveEmployees + record?.totalResignedEmployees
   );
   return round((resign / totalEmployees) * 100, 2);
  });
 }

 let monthResignedEmployeesArray = [];
 if (allData) {
  monthResignedEmployeesArray = allData?.map(
   (record) => record.month && dayjs(record.month).format("MMM")
  );
 }

 let totalStatusTetapArray = [];
 if (allData) {
  totalStatusTetapArray = allData?.map((record) => record.totalStatusTetap);
 }
 const percentDiffStatusTetap = getPercentDiff(totalStatusTetapArray) ?? 0;

 let totalStatusProbationArray = [];
 if (allData) {
  totalStatusProbationArray = allData?.map(
   (record) => record.totalStatusProbation
  );
 }
 const percentDiffStatusProbation =
  getPercentDiff(totalStatusProbationArray) ?? 0;

 let totalStatusOnTheJobTrainingArray = [];
 if (allData) {
  totalStatusOnTheJobTrainingArray = allData?.map(
   (record) => record.totalStatusOnTheJobTraining
  );
 }
 const percentDiffStatusOnTheJobTraining =
  getPercentDiff(totalStatusOnTheJobTrainingArray) ?? 0;

 let monthArray = [];
 if (allData) {
  monthArray = allData?.map(
   (record) => record.month && dayjs(record.month).format("MMM")
  );
 }

 let totalNewEmployeesArray = [];
 if (allData) {
  totalNewEmployeesArray = allData?.map((record) => record.totalNewEmployees);
 }
 const percentDiffNewEmployees = getPercentDiff(totalNewEmployeesArray) ?? 0;

 let totalStatusKontrakArray = [];
 if (allData) {
  totalStatusKontrakArray = allData?.map((record) => record.totalStatusKontrak);
 }
 const percentDiffStatusKontrak = getPercentDiff(totalStatusKontrakArray) ?? 0;

 let totalStatusOutsourcingArray = [];
 if (allData) {
  totalStatusOutsourcingArray = allData?.map(
   (record) => record.totalStatusOutsourcing
  );
 }
 const percentDiffStatusOutsourcing =
  getPercentDiff(totalStatusOutsourcingArray) ?? 0;

 let totalStatusPartTimeArray = [];
 if (allData) {
  totalStatusPartTimeArray = allData?.map(
   (record) => record.totalStatusPartTime
  );
 }
 const percentDiffStatusPartTime =
  getPercentDiff(totalStatusPartTimeArray) ?? 0;

 let totalMaleArray = [];
 if (allData) {
  totalMaleArray = allData?.map((record) => record?.totalMale);
 }

 let totalFemaleArray = [];
 if (allData) {
  totalFemaleArray = allData?.map((record) => record?.totalFemale);
 }

 return (
  <Container>
   <Row>
    <Col>
     <strong>{data?.outlet && `${capitalizeEachWord(data?.outlet)} `}</strong>
     {data?.month
      ? `${dayjs(data.month)?.format("D MMMM YYYY")} - ${dayjs(data.month)
         ?.endOf("month")
         ?.format("D MMMM YYYY")}`
      : ""}
    </Col>
   </Row>
   <Row className="xs">
    <Card className="normal-card">
     <Card.Body>
      <Row>
       <Col md="auto">
        <CWidgetStatsA
         color="primary"
         icon="cil-user"
         value={
          <>
           {data?.totalActiveEmployees ?? 0}{" "}
           <span className="fs-6 fw-normal">
            ({percentDiffActiveEmployees}%
            <CIcon
             icon={
              percentDiffActiveEmployees >= 0.01
               ? cilArrowTop
               : // eslint-disable-next-line
               percentDiffActiveEmployees == 0
               ? ""
               : cilArrowBottom
             }
             color={percentDiffActiveEmployees >= 0.01 ? "success" : "danger"}
             width={15}
             height={15}
            />
            )
           </span>
          </>
         }
         title={useMemo(
          () => (
           <span
            style={{ cursor: "pointer" }}
            onClick={() => toggleModal("active")}
           >
            Active
           </span>
          ),
          [toggleModal]
         )} // Only recompute when toggleModal changes}
         chart={
          <CChartLine
           className="mt-3 mx-3"
           style={{ height: "45px" }}
           data={{
            labels: monthArray,
            datasets: [
             {
              label: "Active",
              backgroundColor: "transparent",
              borderColor: "rgba(255,255,255,.55)",
              pointBackgroundColor: "#5856d6",
              data: totalActiveEmployeesArray,
             },
            ],
           }}
           options={{
            plugins: {
             legend: {
              display: false,
             },
            },
            maintainAspectRatio: false,
            scales: {
             x: {
              border: {
               display: false,
              },
              grid: {
               display: false,
               drawBorder: false,
              },
              ticks: {
               display: false,
              },
             },
             y: {
              min: Math.min(...totalActiveEmployeesArray) - 6,
              max: Math.max(...totalActiveEmployeesArray) + 6,
              display: false,
              grid: {
               display: false,
              },
              ticks: {
               display: false,
              },
             },
            },
            elements: {
             line: {
              borderWidth: 1,
              tension: 0.4,
             },
             point: {
              radius: 0,
              hitRadius: 10,
              hoverRadius: 4,
             },
            },
           }}
          />
         }
        />
       </Col>
       <Col md="auto">
        <CWidgetStatsA
         color="danger"
         icon="cil-user"
         value={
          <>
           {data?.totalResignedEmployees ?? 0}{" "}
           <span className="fs-6 fw-normal">
            ({`${percentDiffResignedEmployees ?? 0}%`}
            <CIcon
             icon={
              percentDiffResignedEmployees >= 0.01
               ? cilArrowTop
               : // eslint-disable-next-line
               percentDiffResignedEmployees == 0
               ? ""
               : cilArrowBottom
             }
             color={percentDiffResignedEmployees <= 0.01 ? "success" : "danger"}
             width={15}
             height={15}
            />
            )
           </span>
          </>
         }
         title={useMemo(
          () => (
           <span
            style={{ cursor: "pointer" }}
            onClick={() => toggleModal("resigned")}
           >
            Resigned
           </span>
          ),
          [toggleModal]
         )} // Only recompute when toggleModal changes}
         chart={
          <CChartBar
           className="mt-3 mx-3"
           style={{ height: "45px" }}
           data={{
            labels: monthArray,
            datasets: [
             {
              label: "Resigned",
              backgroundColor: "rgba(255,255,255,.2)",
              borderColor: "rgba(255,255,255,.55)",
              data: totalResignedEmployeesArray,
              barPercentage: 0.6,
             },
            ],
           }}
           options={{
            maintainAspectRatio: false,
            plugins: {
             legend: {
              display: false,
             },
            },
            scales: {
             x: {
              grid: {
               display: false,
               drawTicks: false,
              },
              ticks: {
               display: false,
              },
             },
             y: {
              border: {
               display: false,
              },
              grid: {
               display: false,
               drawBorder: false,
               drawTicks: false,
              },
              ticks: {
               display: false,
              },
             },
            },
           }}
          />
         }
        />
       </Col>
       <Col md="auto">
        <CWidgetStatsA
         style={{ backgroundColor: "#4A90E2", color: "#FFFFFF" }} // Warna kustom
         icon="cil-user"
         value={
          <>
           {data?.totalNewEmployees ?? 0}{" "}
           <span className="fs-6 fw-normal">
            ({`${percentDiffNewEmployees ?? 0}%`}
            <CIcon
             icon={
              percentDiffNewEmployees >= 0.01
               ? cilArrowTop
               : // eslint-disable-next-line
               percentDiffNewEmployees == 0
               ? ""
               : cilArrowBottom
             }
             color={percentDiffNewEmployees <= 0.01 ? "success" : "danger"}
             width={15}
             height={15}
            />
            )
           </span>
          </>
         }
         //title="New Hire"
         title={
          useMemo(
           () => (
            <span
             style={{ cursor: "pointer" }}
             onClick={() => toggleModal("new")}
            >
             New Hire
            </span>
           ),
           [toggleModal]
          ) // Only recompute when toggleModal changes
         }
         chart={
          <CChartBar
           className="mt-3 mx-3"
           style={{ height: "45px" }}
           data={{
            labels: monthArray,
            datasets: [
             {
              label: "New Hire",
              backgroundColor: "rgba(255,255,255,.2)",
              borderColor: "rgba(255,255,255,.55)",
              data: totalNewEmployeesArray,
              barPercentage: 0.6,
             },
            ],
           }}
           options={{
            maintainAspectRatio: false,
            plugins: {
             legend: {
              display: false,
             },
            },
            scales: {
             x: {
              grid: {
               display: false,
               drawTicks: false,
              },
              ticks: {
               display: false,
              },
             },
             y: {
              border: {
               display: false,
              },
              grid: {
               display: false,
               drawBorder: false,
               drawTicks: false,
              },
              ticks: {
               display: false,
              },
             },
            },
           }}
          />
         }
        />
       </Col>
       <Col md="auto">
        <CChart
         type="doughnut"
         style={{ width: "140px", height: "140px" }}
         data={{
          labels: ["Male", "Female"],
          datasets: [
           {
            backgroundColor: ["red", "pink"],
            data: [data?.totalMale ?? 0, data?.totalFemale ?? 0],
           },
          ],
         }}
         options={{
          plugins: {
           legend: {
            labels: {
             color: "primary",
            },
           },
          },
         }}
        />
       </Col>
       <Col>
        <CChart
         type="line"
         style={{ width: "380px", height: "170px" }}
         data={{
          labels: monthArray,
          datasets: [
           {
            label: "Male",
            backgroundColor: "red",
            borderColor: "red",
            pointBackgroundColor: "white",
            pointBorderColor: "#fff",
            data: totalMaleArray,
           },
           {
            label: "Female",
            backgroundColor: "pink",
            borderColor: "pink",
            pointBackgroundColor: "white",
            pointBorderColor: "#fff",
            data: totalFemaleArray,
           },
          ],
         }}
         options={{
          plugins: {
           legend: {
            labels: {
             color: "danger",
            },
           },
          },
          scales: {
           x: {
            grid: {
             color: "danger",
            },
            ticks: {
             color: "danger",
            },
           },
           y: {
            grid: {
             color: "danger",
            },
            ticks: {
             color: "danger",
            },
           },
          },
         }}
        />
       </Col>
      </Row>
      <Row style={{ fontSize: "70%" }}>
       <Col>note: tidak termasuk karyawan outsourcing</Col>
      </Row>
     </Card.Body>
    </Card>
   </Row>
   <Row className="xs mt-4">
    <Col>
     <strong>Status</strong>
    </Col>
   </Row>
   <Row className="xs">
    <Card className="normal-card">
     <Card.Body>
      <Row className="xs">
       <Col md="auto">
        <CWidgetStatsA
         color="success"
         icon="cil-user"
         value={
          <>
           {data?.totalStatusTetap ?? 0}{" "}
           <span className="fs-6 fw-normal">
            ({percentDiffStatusTetap}%
            <CIcon
             icon={
              percentDiffStatusTetap >= 0.01
               ? cilArrowTop
               : // eslint-disable-next-line
               percentDiffStatusTetap == 0
               ? ""
               : cilArrowBottom
             }
             color={percentDiffStatusTetap <= 0.01 ? "success" : "danger"}
             width={15}
             height={15}
            />
            )
           </span>
          </>
         }
         title={
          useMemo(
           () => (
            <span
             style={{ cursor: "pointer" }}
             onClick={() => toggleModal("tetap")}
            >
             Permanent
            </span>
           ),
           [toggleModal]
          ) // Only recompute when toggleModal changes
         }
         chart={
          <CChartBar
           className="mt-3 mx-3"
           style={{ height: "45px" }}
           data={{
            labels: monthArray,
            datasets: [
             {
              label: "Permanent",
              backgroundColor: "rgba(255,255,255,.2)",
              borderColor: "rgba(255,255,255,.55)",
              data: totalStatusTetapArray,
              barPercentage: 0.6,
             },
            ],
           }}
           options={{
            maintainAspectRatio: false,
            plugins: {
             legend: {
              display: false,
             },
            },
            scales: {
             x: {
              grid: {
               display: false,
               drawTicks: false,
              },
              ticks: {
               display: false,
              },
             },
             y: {
              border: {
               display: false,
              },
              grid: {
               display: false,
               drawBorder: false,
               drawTicks: false,
              },
              ticks: {
               display: false,
              },
             },
            },
           }}
          />
         }
        />
       </Col>
       <Col md="auto">
        <CWidgetStatsA
         color="warning"
         icon="cil-user"
         value={
          <>
           {data?.totalStatusProbation ?? 0}{" "}
           <span className="fs-6 fw-normal">
            ({percentDiffStatusProbation}%
            <CIcon
             icon={
              percentDiffStatusProbation >= 0.01
               ? cilArrowTop
               : // eslint-disable-next-line
               percentDiffStatusProbation == 0
               ? ""
               : cilArrowBottom
             }
             color={percentDiffStatusProbation <= 0.01 ? "success" : "danger"}
             width={15}
             height={15}
            />
            )
           </span>
          </>
         }
         title={
          useMemo(
           () => (
            <span
             style={{ cursor: "pointer" }}
             onClick={() => toggleModal("probation")}
            >
             Probation
            </span>
           ),
           [toggleModal]
          ) // Only recompute when toggleModal changes
         }
         chart={
          <CChartBar
           className="mt-3 mx-3"
           style={{ height: "45px" }}
           data={{
            labels: monthArray,
            datasets: [
             {
              label: "Probation",
              backgroundColor: "rgba(255,255,255,.2)",
              borderColor: "rgba(255,255,255,.55)",
              data: totalStatusProbationArray,
              barPercentage: 0.6,
             },
            ],
           }}
           options={{
            maintainAspectRatio: false,
            plugins: {
             legend: {
              display: false,
             },
            },
            scales: {
             x: {
              grid: {
               display: false,
               drawTicks: false,
              },
              ticks: {
               display: false,
              },
             },
             y: {
              border: {
               display: false,
              },
              grid: {
               display: false,
               drawBorder: false,
               drawTicks: false,
              },
              ticks: {
               display: false,
              },
             },
            },
           }}
          />
         }
        />
       </Col>
       <Col md="auto">
        <CWidgetStatsA
         color="primary"
         icon="cil-user"
         value={
          <>
           {data?.totalStatusKontrak ?? 0}{" "}
           <span className="fs-6 fw-normal">
            ({`${percentDiffStatusKontrak ?? 0}%`}
            <CIcon
             icon={
              percentDiffStatusKontrak >= 0.01
               ? cilArrowTop
               : // eslint-disable-next-line
               percentDiffStatusKontrak == 0
               ? ""
               : cilArrowBottom
             }
             color={percentDiffStatusKontrak <= 0.01 ? "success" : "danger"}
             width={15}
             height={15}
            />
            )
           </span>
          </>
         }
         title={
          useMemo(
           () => (
            <span
             style={{ cursor: "pointer" }}
             onClick={() => toggleModal("kontrak")}
            >
             Contract
            </span>
           ),
           [toggleModal]
          ) // Only recompute when toggleModal changes
         }
         chart={
          <CChartBar
           className="mt-3 mx-3"
           style={{ height: "45px" }}
           data={{
            labels: monthArray,
            datasets: [
             {
              label: "Contract",
              backgroundColor: "rgba(255,255,255,.2)",
              borderColor: "rgba(255,255,255,.55)",
              data: totalStatusKontrakArray,
              barPercentage: 0.6,
             },
            ],
           }}
           options={{
            maintainAspectRatio: false,
            plugins: {
             legend: {
              display: false,
             },
            },
            scales: {
             x: {
              grid: {
               display: false,
               drawTicks: false,
              },
              ticks: {
               display: false,
              },
             },
             y: {
              border: {
               display: false,
              },
              grid: {
               display: false,
               drawBorder: false,
               drawTicks: false,
              },
              ticks: {
               display: false,
              },
             },
            },
           }}
          />
         }
        />
       </Col>

       <Col md="auto">
        <CWidgetStatsA
         color="secondary"
         icon="cil-user"
         value={
          <>
           {data?.totalStatusOnTheJobTraining ?? 0}{" "}
           <span className="fs-6 fw-normal">
            ({percentDiffStatusOnTheJobTraining}%
            <CIcon
             icon={
              percentDiffStatusOnTheJobTraining >= 0.01
               ? cilArrowTop
               : // eslint-disable-next-line
               percentDiffStatusOnTheJobTraining == 0
               ? ""
               : cilArrowBottom
             }
             color={
              percentDiffStatusOnTheJobTraining <= 0.01 ? "success" : "danger"
             }
             width={15}
             height={15}
            />
            )
           </span>
          </>
         }
         title={
          useMemo(
           () => (
            <span
             style={{ cursor: "pointer" }}
             onClick={() => toggleModal("onTheJobTraining")}
            >
             On The Job Training
            </span>
           ),
           [toggleModal]
          ) // Only recompute when toggleModal changes
         }
         chart={
          <CChartBar
           className="mt-3 mx-3"
           style={{ height: "45px" }}
           data={{
            labels: monthArray,
            datasets: [
             {
              label: "On The Job Training",
              backgroundColor: "rgba(255,255,255,.2)",
              borderColor: "rgba(255,255,255,.55)",
              data: totalStatusOnTheJobTrainingArray,
              barPercentage: 0.6,
             },
            ],
           }}
           options={{
            maintainAspectRatio: false,
            plugins: {
             legend: {
              display: false,
             },
            },
            scales: {
             x: {
              grid: {
               display: false,
               drawTicks: false,
              },
              ticks: {
               display: false,
              },
             },
             y: {
              border: {
               display: false,
              },
              grid: {
               display: false,
               drawBorder: false,
               drawTicks: false,
              },
              ticks: {
               display: false,
              },
             },
            },
           }}
          />
         }
        />
       </Col>
       <Col md="auto">
        <CWidgetStatsA
         color="warning"
         icon="cil-user"
         value={
          <>
           {data?.totalStatusPartTime ?? 0}{" "}
           <span className="fs-6 fw-normal">
            ({percentDiffStatusPartTime}%
            <CIcon
             icon={
              percentDiffStatusPartTime >= 0.01
               ? cilArrowTop
               : // eslint-disable-next-line
               percentDiffStatusPartTime == 0
               ? ""
               : cilArrowBottom
             }
             color={percentDiffStatusPartTime <= 0.01 ? "success" : "danger"}
             width={15}
             height={15}
            />
            )
           </span>
          </>
         }
         title={
          useMemo(
           () => (
            <span
             style={{ cursor: "pointer" }}
             onClick={() => toggleModal("partTime")}
            >
             Part Time
            </span>
           ),
           [toggleModal]
          ) // Only recompute when toggleModal changes
         }
         chart={
          <CChartBar
           className="mt-3 mx-3"
           style={{ height: "45px" }}
           data={{
            labels: monthArray,
            datasets: [
             {
              label: "Part Time",
              backgroundColor: "rgba(255,255,255,.2)",
              borderColor: "rgba(255,255,255,.55)",
              data: totalStatusPartTimeArray,
              barPercentage: 0.6,
             },
            ],
           }}
           options={{
            maintainAspectRatio: false,
            plugins: {
             legend: {
              display: false,
             },
            },
            scales: {
             x: {
              grid: {
               display: false,
               drawTicks: false,
              },
              ticks: {
               display: false,
              },
             },
             y: {
              border: {
               display: false,
              },
              grid: {
               display: false,
               drawBorder: false,
               drawTicks: false,
              },
              ticks: {
               display: false,
              },
             },
            },
           }}
          />
         }
        />
       </Col>
       <Col md="auto">
        <CWidgetStatsA
         color="secondary"
         icon="cil-user"
         value={
          <>
           {data?.totalStatusOutsourcing ?? 0}{" "}
           <span className="fs-6 fw-normal">
            ({percentDiffStatusOutsourcing}%
            <CIcon
             icon={
              percentDiffStatusOutsourcing >= 0.01
               ? cilArrowTop
               : // eslint-disable-next-line
               percentDiffStatusOutsourcing == 0
               ? ""
               : cilArrowBottom
             }
             color={percentDiffStatusOutsourcing <= 0.01 ? "success" : "danger"}
             width={15}
             height={15}
            />
            )
           </span>
          </>
         }
         title={
          useMemo(
           () => (
            <span
             style={{ cursor: "pointer" }}
             onClick={() => toggleModal("outsourcing")}
            >
             Outsourcing
            </span>
           ),
           [toggleModal]
          ) // Only recompute when toggleModal changes
         }
         chart={
          <CChartBar
           className="mt-3 mx-3"
           style={{ height: "45px" }}
           data={{
            labels: monthArray,
            datasets: [
             {
              label: "Outsourcing",
              backgroundColor: "rgba(255,255,255,.2)",
              borderColor: "rgba(255,255,255,.55)",
              data: totalStatusOutsourcingArray,
              barPercentage: 0.6,
             },
            ],
           }}
           options={{
            maintainAspectRatio: false,
            plugins: {
             legend: {
              display: false,
             },
            },
            scales: {
             x: {
              grid: {
               display: false,
               drawTicks: false,
              },
              ticks: {
               display: false,
              },
             },
             y: {
              border: {
               display: false,
              },
              grid: {
               display: false,
               drawBorder: false,
               drawTicks: false,
              },
              ticks: {
               display: false,
              },
             },
            },
           }}
          />
         }
        />
       </Col>
      </Row>
      <Row style={{ fontSize: "70%" }}>
       <Col>note: hanya karyawan aktif / belum resign</Col>
      </Row>
     </Card.Body>
    </Card>
   </Row>
   <Row className="xs mt-4">
    <Col>
     <strong>Key Performance Indicator</strong>
    </Col>
   </Row>
   <Row className="xs">
    <Card className="normal-card">
     <Card.Body>
      <Row className="xs">
       <Col md="auto">
        <CWidgetStatsB
         title="Turn Over Rate"
         color="dark"
         inverse
         progress={{
          color:
           latestAttendanceRate <= 10
            ? "success"
            : latestAttendanceRate <= 20
            ? "warning"
            : "danger",
          value: latestAttendanceRate,
         }}
         value={
          <span
           style={{
            color:
             latestAttendanceRate <= 10
              ? "green"
              : latestAttendanceRate <= 20
              ? "orange"
              : "red",
           }}
          >
           {`${latestAttendanceRate}%`}
          </span>
         }
         text={`${latestTotalResignedEmployees}/${
          Number(latestTotalActiveEmployees) +
          Number(latestTotalResignedEmployees)
         }`}
        />
       </Col>
       <Col>
        <CChart
         type="bar"
         style={{ width: "380px", height: "170px" }}
         data={{
          labels: monthArray,
          datasets: [
           {
            label: `Turn Over Rate${
             data?.month ? ` ${dayjs(data.month).format("YYYY")} (%)` : ""
            }`,
            backgroundColor: "#f87979",
            data: turnOverRateArray,
           },
          ],
         }}
         labels="months"
         options={{
          plugins: {
           legend: {
            labels: {
             color: "danger",
            },
           },
          },
          scales: {
           x: {
            grid: {
             color: "danger",
            },
            ticks: {
             color: "danger",
            },
           },
           y: {
            grid: {
             color: "danger",
            },
            ticks: {
             color: "danger",
            },
           },
          },
         }}
        />
       </Col>
      </Row>
     </Card.Body>
    </Card>
   </Row>
   <Row className="mt-5">
    <CChart
     type="bar"
     style={{ width: "40%", height: "300px" }} // Width adjusts to 40% of the parent container
     data={{
      labels: monthActiveEmployeesArray,
      datasets: [
       {
        label: `Active Employees${
         data?.month ? ` ${dayjs(data.month).format("YYYY")}` : ""
        }`,
        backgroundColor: "#17a2b8",
        data: totalActiveEmployeesArray,
       },
      ],
     }}
     labels="months"
     options={{
      plugins: {
       legend: {
        labels: {
         color: "danger",
        },
       },
      },
      scales: {
       x: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
       y: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
      },
     }}
    />
    <CChart
     type="bar"
     style={{ width: "40%", height: "300px" }} // Width adjusts to 40% of the parent container
     data={{
      labels: monthResignedEmployeesArray,
      datasets: [
       {
        label: `Resigned Employees${
         data?.month ? ` ${dayjs(data.month).format("YYYY")}` : ""
        }`,
        backgroundColor: "#f87979",
        data: totalResignedEmployeesArray,
       },
      ],
     }}
     labels="months"
     options={{
      plugins: {
       legend: {
        labels: {
         color: "danger",
        },
       },
      },
      scales: {
       x: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
       y: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
      },
     }}
    />
   </Row>
   <Row>
    <CChart
     type="bar"
     style={{ width: "40%", height: "300px" }} // Width adjusts to 40% of the parent container
     data={{
      labels: monthArray,
      datasets: [
       {
        label: `Permanent Employees${
         data?.month ? ` ${dayjs(data.month).format("YYYY")}` : ""
        }`,
        backgroundColor: "green",
        data: totalStatusTetapArray,
       },
      ],
     }}
     labels="months"
     options={{
      plugins: {
       legend: {
        labels: {
         color: "danger",
        },
       },
      },
      scales: {
       x: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
       y: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
      },
     }}
    />
    <CChart
     type="bar"
     style={{ width: "40%", height: "300px" }} // Width adjusts to 40% of the parent container
     data={{
      labels: monthArray,
      datasets: [
       {
        label: `Probation Employees${
         data?.month ? ` ${dayjs(data.month).format("YYYY")}` : ""
        }`,
        backgroundColor: "orange",
        data: totalStatusProbationArray,
       },
      ],
     }}
     labels="months"
     options={{
      plugins: {
       legend: {
        labels: {
         color: "danger",
        },
       },
      },
      scales: {
       x: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
       y: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
      },
     }}
    />
   </Row>
   <Row>
    <CChart
     type="bar"
     style={{ width: "40%", height: "300px" }} // Width adjusts to 40% of the parent container
     data={{
      labels: monthArray,
      datasets: [
       {
        label: `On The Job Training Employees${
         data?.month ? ` ${dayjs(data.month).format("YYYY")}` : ""
        }`,
        backgroundColor: "gray",
        data: totalStatusOnTheJobTrainingArray,
       },
      ],
     }}
     labels="months"
     options={{
      plugins: {
       legend: {
        labels: {
         color: "danger",
        },
       },
      },
      scales: {
       x: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
       y: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
      },
     }}
    />
    <CChart
     type="bar"
     style={{ width: "40%", height: "300px" }} // Width adjusts to 40% of the parent container
     data={{
      labels: monthArray,
      datasets: [
       {
        label: `New Hire Employees${
         data?.month ? ` ${dayjs(data.month).format("YYYY")}` : ""
        }`,
        backgroundColor: "lightblue",
        data: totalNewEmployeesArray,
       },
      ],
     }}
     labels="months"
     options={{
      plugins: {
       legend: {
        labels: {
         color: "danger",
        },
       },
      },
      scales: {
       x: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
       y: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
      },
     }}
    />
   </Row>
   <EmployeeSummaryModal
    Title="Active Employees"
    employees={data?.employees?.active}
    visible={modalVisible?.employees?.active}
    onClose={() => {
     if (modalVisible?.employees?.active) {
      toggleModal("active");
     }
    }}
   />
   <EmployeeSummaryModal
    Title="Resigned Employees"
    employees={data?.employees?.resigned}
    visible={modalVisible?.employees?.resigned}
    onClose={() => {
     if (modalVisible?.employees?.resigned) {
      toggleModal("resigned");
     }
    }}
   />
   <EmployeeSummaryModal
    Title="New Employees"
    employees={data?.employees?.new}
    visible={modalVisible?.employees?.new}
    onClose={() => {
     if (modalVisible?.employees?.new) {
      toggleModal("new");
     }
    }}
   />
   <EmployeeSummaryModal
    Title="Permanent Employees"
    employees={data?.employees?.active?.filter((item) =>
     stringIncludes(item?.status, "tetap")
    )}
    visible={modalVisible?.employees?.tetap}
    onClose={() => {
     if (modalVisible?.employees?.tetap) {
      toggleModal("tetap");
     }
    }}
   />
   <EmployeeSummaryModal
    Title="Probation Employees"
    employees={data?.employees?.active?.filter((item) =>
     stringIncludes(item?.status, "probation")
    )}
    visible={modalVisible?.employees?.probation}
    onClose={() => {
     if (modalVisible?.employees?.probation) {
      toggleModal("probation");
     }
    }}
   />
   <EmployeeSummaryModal
    Title="Contract Employees"
    employees={data?.employees?.active?.filter((item) =>
     stringIncludes(item?.status, "kontrak")
    )}
    visible={modalVisible?.employees?.kontrak}
    onClose={() => {
     if (modalVisible?.employees?.kontrak) {
      toggleModal("kontrak");
     }
    }}
   />
   <EmployeeSummaryModal
    Title="On The Job Training Employees"
    employees={data?.employees?.active?.filter((item) =>
     stringIncludes(item?.status, "on the job training")
    )}
    visible={modalVisible?.employees?.onTheJobTraining}
    onClose={() => {
     if (modalVisible?.employees?.onTheJobTraining) {
      toggleModal("onTheJobTraining");
     }
    }}
   />
   <EmployeeSummaryModal
    Title="Part Time Employees"
    employees={data?.employees?.active?.filter((item) =>
     stringIncludes(item?.status, "part time")
    )}
    visible={modalVisible?.employees?.partTime}
    onClose={() => {
     if (modalVisible?.employees?.partTime) {
      toggleModal("partTime");
     }
    }}
   />
   <EmployeeSummaryModal
    Title="Outsourcing Employees"
    employees={data?.employees?.outsourcing}
    visible={modalVisible?.employees?.outsourcing}
    onClose={() => {
     if (modalVisible?.employees?.outsourcing) {
      toggleModal("outsourcing");
     }
    }}
   />
  </Container>
 );
};

export default AnaliticsOverview;
